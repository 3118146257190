import { Checkbox, TableCell, TableRow } from "@material-ui/core";
import "assets/styles/base.scss";
import React, { useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  deleteActivity,
  editActivityStatus,
  loadActivitiesList,
} from "../../reducers/Activities";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import Switch from "../Switch";
import "./ActivityDataTable.css";

const ActivityDataTable = (props) => {
  const [show, setShow] = useState(false);

  const {
    activity,
    editStatus,
    loadAllActivities,
    deleteActivity,
    isItemSelected,
    handleClick,
    labelId,
    data,
    headSelected,
  } = props;

  const openModalHandler = () => setShow(true);

  const handleEditActivityStatus = () => {
    editStatus({
      id: data.id,
      new_status: data.isactive === 1 ? 0 : 1,
    }).then(() => {
      data.isactive = data.isactive === 1 ? 0 : 1;
      loadAllActivities();
    });
  };

  const handleDeleteActivity = () => {
    deleteActivity({ id: data.id }).then(() => {
      loadAllActivities();
    });
  };

  return (
    <React.Fragment>
      <TableRow
        hover
      
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={data.intID}
        selected={isItemSelected}
      >
        <TableCell padding="checkbox">
          <Checkbox
            onClick={(event) => handleClick(event, data.id)}
            role="checkbox"
            checked={isItemSelected}
            inputProps={{ "aria-labelledby": labelId }}
          />
        </TableCell>

        {headSelected.id && <TableCell>{data.id}</TableCell>}
        {headSelected.intID && <TableCell>{data.intID}</TableCell>}
        {headSelected.nameAR && <TableCell>{data.nameAR}</TableCell>}
        {headSelected.nameEN && <TableCell>{data.nameEN}</TableCell>}
        {headSelected.infoAR && <TableCell>{data.infoAR}</TableCell>}
        {headSelected.infoEN && <TableCell>{data.infoEN}</TableCell>}
        {headSelected.order && <TableCell>{data.order}</TableCell>}
        {headSelected.countryid && <TableCell>{data.countryid}</TableCell>}
        {headSelected.Deeplink && (
          <TableCell>
            ikhair://screen?target=Activ_{data.intID}
            <i
              className="fa fa-clone pointer"
              onClick={() => {
                navigator.clipboard.writeText(
                  `ikhair://screen?target=cause_${data.intID}`
                );
              }}
            ></i>
          </TableCell>
        )}
        {headSelected.Status && (
          <TableCell>
            <Switch
              value={data.isactive}
              offText={"OFF"}
              onText={"Active"}
              onChange={handleEditActivityStatus}
            />
          </TableCell>
        )}
        {headSelected.Actions && (
          <TableCell >
            <div className="TableIcons">
              
            <Link to={`/editActivity/${data.id}`}>
              <FaRegEdit size={24} color="#00AEEF" />
            </Link>
            <RiDeleteBinLine
              cursor="pointer"
              onClick={openModalHandler}
              color="#ED1650"
              size={24}
            />
            </div>
          </TableCell>
        )}
      </TableRow>
      {show && (
        <ConfirmModal
          handleClose={() => setShow(false)}
          handleShow={() => setShow(true)}
          message={`Are you sure you want to delete ${data.nameEN} from Activities?`}
          setShow={setShow}
          show={show}
        >
          <button className="btn btn-danger" onClick={handleDeleteActivity}>
            Delete
          </button>
        </ConfirmModal>
      )}
    </React.Fragment>
  );
};

const mapDispatchToProp = (dispatch) => ({
  editStatus: (data) => dispatch(editActivityStatus(data)),
  loadAllActivities: () => dispatch(loadActivitiesList()),
  deleteActivity: (activity_id) => dispatch(deleteActivity(activity_id)),
});

export default connect(() => ({}), mapDispatchToProp)(ActivityDataTable);
