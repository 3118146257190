import lodash from "lodash";
import networkService from "../api";
import toaster from "../modules/toaster";
import uploadOnS3 from "../helpers/uploadOnS3";

const defaultState = {
  banners: [],
  banner: null,
  duplicate: null,
  error: null,
  isPending: null,
  loaded: false,
  bannerImage:""
};
const CREATE_BANNER = "CREATE_BANNER";
const EDIT_BANNER = "EDIT_BANNER";
const GET_BANNERS = "GET_BANNERS";
const GET_BANNER = "GET_BANNER";
const DELETE_BANNER = "DELETE_BANNER";
const BANNER_DUPLICATE = "BANNER_DUPLICATE";
const BANNERS_PENDING = "BANNERS_PENDING";
const BANNERS_ERROR = "BANNERS_ERROR";
const DELETE_BANNERS= "DELETE_BANNERS";
const Banner_IMAGE= "Banner_IMAGE"
export const getBanners = (orgId) => (dispatch) => {
  dispatch({
    type: BANNERS_PENDING,
    payload: true,
  });
  let data = {};
  if (orgId && orgId != "") data.org_id = orgId;
  networkService
    .send("banners", data)
    .then((res) => {
      dispatch({
        type: GET_BANNERS,
        banners: res.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: BANNERS_ERROR,
        error,
      });
    });
};
export const createBanner = (banner) => async (dispatch) => {
  dispatch({
    type: BANNERS_PENDING,
  });
  try {
    await networkService.send("createBanner", banner);
    dispatch({
      type: CREATE_BANNER,
    });
  } catch (error) {
    dispatch({
      type: BANNERS_ERROR,
      error,
    });
  }
};
export const editBannerStatus = (data) => async (dispatch) => {
  dispatch({ type: BANNERS_PENDING });
  try {
    await networkService.send("editBanner", data);
    dispatch({
      type: EDIT_BANNER,
      payload: data.id,
    });
  } catch (error) {
    dispatch({
      type: BANNERS_ERROR,
      error,
    });
  }
};
export const duplicateBanner = (data) => async (dispatch) => {
  dispatch({ type: BANNERS_PENDING });
  try {
    let res = await networkService.send("getBanner", { id: data });
    dispatch({
      type: BANNER_DUPLICATE,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: BANNERS_ERROR,
      error,
    });
  }
};
export const getBanner = (id) => async (dispatch) => {
  dispatch({
    type: BANNERS_PENDING,
  });
  try {
    dispatch({
      type: GET_BANNER,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: BANNERS_ERROR,
      error,
    });
  }
};
export const deleteBanner = (id) => async (dispatch) => {
  dispatch({ type: BANNERS_PENDING });
  try {
    await networkService.send("deleteBanner", { id });
    dispatch({
      type: DELETE_BANNER,
    });
  } catch (error) {
    dispatch({
      type: BANNERS_ERROR,
      error,
    });
  }
};

export const deleteBanners = (bannersIDs) => async (dispatch) => {
  dispatch({ type: BANNERS_PENDING });
  try {
    await networkService.send("deleteBanners",  {bannersIDs} );
    dispatch({
      type: DELETE_BANNERS,
    });
    toaster.success('successfully deleted banners')
  } catch (error) {
    toaster.error(error)
    dispatch({
      type: BANNERS_ERROR,
      error,
    });
  }
};
export const uploadBannerImage = (image, id) => async (dispatch) => {
  dispatch({ type: GET_BANNERS, payload: true });

  try {
    const data = await uploadOnS3(image, `${id}`, "sliders");
    dispatch({ type: Banner_IMAGE, payload: data.Location });
  } catch (error) {
    dispatch({ type: GET_BANNERS, payload: error });
  }
};
export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case BANNERS_PENDING:
      return { ...state, isPending: true };
    case GET_BANNERS:
      return {
        ...state,
        banners: lodash.orderBy(action.banners, ["isactive"], ["desc"]),
        loaded: true,
        isPending: false,
      };
    case GET_BANNER:
      return {
        ...state,
        banner: lodash.filter(state.banners, (banner) => {
          return banner.id === Number(action.payload);
        }),
        error: false,
        isPending: false,
      };
    case CREATE_BANNER:
      return {
        ...state,
        isPending: false,
        error: false,
      };
    case EDIT_BANNER:
      return {
        ...state,
        banner: lodash.filter(state.banners, (banner) => {
          return banner.id === action.payload;
        }),
        isPending: false,
        error: false,
      };
    case BANNER_DUPLICATE:
      return {
        ...state,
        duplicate: action.payload,
        isPending: false,
        error: action.error,
      };
    case DELETE_BANNER:
      return {
        ...state,
        banners: lodash.filter(state.banners, (banner) => {
          return banner.id !== action.payload;
        }),
        isPending: false,
        error: false,
      };
    case DELETE_BANNERS:
      return {
        ...state,
        banners: lodash.filter(state.banners, (banner) => {
          return banner.id !== action.payload;
        }),
        isPending: false,
        error: false,
      };
    case BANNERS_ERROR:
      return {
        ...state,
        isPending: false,
      };
      case Banner_IMAGE:
        return{
          ...state,
          bannerImage: action.payload,
          isPending: false,
          loaded: true,
        }
      default:
      return state;
  }
}
