import "assets/styles/base.scss";
import axios from "axios";
import PropTypes from "prop-types";
import React from "react";
import { FiLogOut } from "react-icons/fi";
import { connect } from "react-redux";
import updateBlogsImage from "../../assets/images/updateBlogs.svg";
import updateCasesImage from "../../assets/images/updateCases.svg";
import updateProjectsImage from "../../assets/images/updateProjects.svg";
import SelectOrg from "../../components/SelectOrg";
import toaster from "../../modules/toaster";
import { updateCases } from "../../reducers/Cases";
import { toggleMobileNavVisibility } from "../../reducers/Layout";
import { setSelectedOrg } from "../../reducers/ORGs";
import { updateProjects } from "../../reducers/Projects";
// import "../../assets/styles/navbar.scss"

const Header = ({ updateCases, updateProjects }) => {
  const handleLogout = () => {
    sessionStorage.clear();
    window.location.reload(false);
  };

  const updateWebsiteBlogs = async () => {
    try {
      await axios.get(
        "https://api.vercel.com/v1/integrations/deploy/prj_q6TAsorzKuceRGSIV91qX4w4HOZa/pOlKQr4dAu"
      );
      toaster.success("publishing done successfully");
    } catch (error) {
      toaster.error("publishing failed");
    }
  };

  return (
    <div className="header navbar mobile">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "0 20px",
        }}
      >
        <SelectOrg />

        <button className="updateButton" onClick={() => updateCases()}>
          <img
            src={updateCasesImage}
            alt="updateCases"
            width={24}
            height={24}
          />
          Update Cases
        </button>
        <button className="updateButton" onClick={() => updateProjects()}>
          <img
            src={updateProjectsImage}
            alt="updateProjects"
            width={24}
            height={24}
          />
          Update Projects
        </button>
        <button className="updateButton" onClick={() => updateWebsiteBlogs()}>
          <img
            src={updateBlogsImage}
            alt="updateBlogs"
            width={24}
            height={24}
          />
          Update Website Blogs
        </button>

        <button className="logout" onClick={handleLogout}>
          <FiLogOut size={24} color="red" />
          Log out
        </button>
      </div>
    </div>
  );
};

const mapStateToProp = (state) => ({
  orgs: state.ORGs.orgs,
  isPending:
    state.ORGs.isPending ||
    state.Activities.isPending ||
    state.MNOs.isPending ||
    state.Projects.isPending ||
    state.Cases.isPending ||
    state.Banners.isPending ||
    state.Keywords.isPending ||
    state.Reports.isPending,
  selectedOrg: state.ORGs.selectedOrg,
});

const mapDispatchToProp = (dispatch) => ({
  toggleMobileNavVisibility: () => dispatch(toggleMobileNavVisibility()),
  selectOrg: (org) => dispatch(setSelectedOrg(org)),
  updateProjects: () => dispatch(updateProjects()),
  updateCases: () => dispatch(updateCases()),
});

export default connect(mapStateToProp, mapDispatchToProp)(Header);

Header.propTypes = {
  updateCases: PropTypes.func,
  updateProjects: PropTypes.func,
};
