import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button, FormControl } from "react-bootstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { connect } from "react-redux";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import {
  createNewRamadanNotes,
  editRamadanNote,
  getSingleRamadanNote,
} from "../../reducers/RamadanNotes";

const AddNewRamadanNote = ({
  singleRamadanNote,
  isPending,
  getSingleRamadanNote,
  editRamadanNote,
  createNewRamadanNotes,
  history,
}) => {
  const ramadanNoteId =
    window.location.hash.includes("editRamadanNote") &&
    window.location.hash.split("/")[window.location.hash.split("/").length - 1];
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [content, setContent] = useState("");
  const [name, setName] = useState("");
  const [day, setDay] = useState("");

  const submitRamadanNoteDataHandler = (e) => {
    e.preventDefault();

    const ramadanNoteData = {
      content,
      name,
      daynum:day,
    };

    if (isEditingMode) {
      editRamadanNote(ramadanNoteId, ramadanNoteData);
    } else {
      createNewRamadanNotes(ramadanNoteData);
    }
    history.push("/ramadanNotes");
  };

  useEffect(() => {
    if (ramadanNoteId) {
      getSingleRamadanNote(ramadanNoteId);
      setIsEditingMode(true);
      setContent(singleRamadanNote.content);
      setName(singleRamadanNote.name);
      setDay(singleRamadanNote.daynum);

    } else {
      setIsEditingMode(false);
    }
  }, [ramadanNoteId, singleRamadanNote.content]);

  if (isPending) return <LoadingSpinner />;

  return (
    <div>
      <div className="header">
        <h4 className="title">
          {isEditingMode
            ? `Edit Ramadan Note: ${singleRamadanNote.content}`
            : "Add New Ramadan Note"}
        </h4>
      </div>
      <form
        className="card"
        style={{ minHeight: "auto", padding: "12px 24px" }}
        onSubmit={submitRamadanNoteDataHandler}
      >
        <div className="formGroup">
          <div className="fieldDiv" style={{ width: "50%" }}>
            <label id="content">Content</label>
            <FormControl
              type="text"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              name="content"
              htmlFor="content"
              placeholder="Enter Content"
              required
            />
          </div>
        </div>
        <div className="formGroup">
          <div className="fieldDiv" style={{ width: "50%" }}>
            <label id="name">Name</label>
            <FormControl
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              name="name"
              htmlFor="name"
              placeholder="Enter Name"
              required
            />
          </div>
          <div className="fieldDiv" style={{ width: "50%" }}>
            <label id="day">Day Number</label>
            <FormControl
              type="number"
              value={day}
              onChange={(e) => setDay(e.target.value)}
              name="day"
              htmlFor="day"
              placeholder="Enter Day Number"
              required
            />
          </div>
        </div>

        <div className="submit">
          <Button className="create-edit-form-btn" type="submit">
            {isEditingMode ? "Edit" : "Submit"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default connect(
  (state) => ({
    singleRamadanNote: state.RamadanNotes.singleRamadanNote,
    isPending: state.RamadanNotes.isPending,
  }),
  (dispatch) => ({
    getSingleRamadanNote: (id) => dispatch(getSingleRamadanNote(id)),
    editRamadanNote: (id, data) => dispatch(editRamadanNote(id, data)),
    createNewRamadanNotes: (data) => dispatch(createNewRamadanNotes(data)),
  })
)(AddNewRamadanNote);

AddNewRamadanNote.propTypes = {
  singleRamadanNote: PropTypes.object,
  isPending: PropTypes.bool,
  getSingleRamadanNote: PropTypes.func,
  editRamadanNote: PropTypes.func,
  createNewRamadanNotes: PropTypes.func,
  history: PropTypes.object,
};
