import axios from "axios";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import logoImage from "../../assets/images/logo-light.svg";
import updateBlogsImage from "../../assets/images/updateBlogs.svg";
import updateCasesImage from "../../assets/images/updateCases.svg";
import updateProjectsImage from "../../assets/images/updateProjects.svg";
import toaster from "../../modules/toaster";
import { updateCases } from "../../reducers/Cases";
import { updateProjects } from "../../reducers/Projects";
import Nav from "./Nav";

class SideBar extends Component {
  state = {
    open: false,
  };

  updateWebsiteBlogs = async () => {
    try {
      // fetch(
      //   "https://api.vercel.com/v1/integrations/deploy/prj_q6TAsorzKuceRGSIV91qX4w4HOZa/pOlKQr4dAu",
      //   {
      //     method: "GET",
      //   }
      // );
      await axios.get(
        "https://api.vercel.com/v1/integrations/deploy/prj_q6TAsorzKuceRGSIV91qX4w4HOZa/pOlKQr4dAu"
      );
      toaster.success("publishing done successfully");
    } catch (error) {
      toaster.error("publishing failed");
    }
  };
  render() {
    const handleLogout = () => {
      sessionStorage.clear();
      window.location.reload(false);
    };

    return (
      <>
        {/* <AiOutlineMenu onClick={this.setState({open:true})}

      /> */}

        <div
          className={`sidebar ${
            this.props.mobileMenu ? "displayMenu" : "nonDisplayMenue"
          }`}
        >
          <div className="brand">
            <a href="#/projects/list" className="brand-name">
              <img src={logoImage} alt="logo" className="logo" />
            </a>
          </div>

          <div className="sidebar-wrapper">
            <div className="line"></div>
            <Nav mobileMenu={this.props.mobileMenu} />
            <div className="mobileBtn">
              <button
                className="updateButton"
                onClick={() => this.props.updateCases()}
              >
                <img
                  src={updateCasesImage}
                  alt="updateCases"
                  width={24}
                  height={24}
                />
                Update Cases
              </button>
              <button
                className="updateButton"
                onClick={() => this.props.updateProjects()}
              >
                <img
                  src={updateProjectsImage}
                  alt="updateProjects"
                  width={24}
                  height={24}
                />
                Update Projects
              </button>
              <button
                className="updateButton"
                onClick={() => this.updateWebsiteBlogs()}
              >
                <img
                  src={updateBlogsImage}
                  alt="updateBlogs"
                  width={24}
                  height={24}
                />
                Update Website Blogs
              </button>
            </div>

            <button className="logoutMobile" onClick={handleLogout}>
              Log out <i className="fa fa-sign-out logoutIcon"></i>
            </button>
          </div>
          <div className="sidebar-background"></div>
        </div>
      </>
    );
  }
}

// export default withRouter(connect()(SideBar));
const mapDispatchToProp = (dispatch) => ({
  updateProjects: () => dispatch(updateProjects()),
  updateCases: () => dispatch(updateCases()),
});

export default connect(mapDispatchToProp)(SideBar);

SideBar.propTypes = {
  updateCases: PropTypes.func,
  updateProjects: PropTypes.func,
};
