import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { BsPlus } from "react-icons/bs";
import { connect } from "react-redux";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NewDataTable from "../../components/NewDataTable";
import ShortCodesDataTable from "../../components/ShortCodesDataTable/ShortCodesDataTable";
import {
    deleteShortCode,
    getAllShortCodes,
} from "../../reducers/ShortCodes";
import { Pagination } from "@material-ui/lab";
import toaster from "../../modules/toaster";

const selecttableHead = [
    "id",
    "mnoid",
    "orgid",
    "price",
    "shortcode",
    "Actions",
];

const rowsPerPage = 10;

const ShortCodes = ({
    isPending,
    loadShortCodes,
    allShortCodes,
    history,
    deleteShortCode,
}) => {

    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [headSelected, setHeadSelected] = React.useState({
        id: true,
        mnoid: true,
        orgid: true,
        price: true,
        shortcode: true,
        Actions: true,
    });
    function getKeyByValue(object, value) {
        return Object.keys(object).filter((key) => object[key] === value);
    }
    const TableHead = getKeyByValue(headSelected, true);
    const handleChange = (event) => {
        setHeadSelected({
            ...headSelected,
            [event.target.name]: event.target.checked,
        });
    };
    useEffect(() => {
        loadShortCodes();
    }, []);

    const addNewShortCode = () => {
        history.push("addNewShortCode");
    };

    if (isPending) return <LoadingSpinner />;


    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;
    const handlePaginationChange = (event, value) => {
        setPage(value);
    };

    const handleDelete = () => {
        if (selected.length === 0) {
            toaster.warning("please select some cases");
        } else {
            Promise.allSettled(
                selected.map((e) => {
                    return deleteShortCode(e);
                })
            ).then((responseArr) => {
                responseArr.forEach((res) => {
                    // console.log(res);
                });
            });
            setSelected([])

        }
    };
    return (
        <div>
            <div className="header">
                <h4 className="title">Short Codes</h4>
            </div>
            <div className="card">
                {allShortCodes?.length > 0 ? (
                    <NewDataTable
                        title={"Add New Short Code"}
                        csvTitle={"Short Codes"}
                        add={addNewShortCode}
                        data={allShortCodes}
                        tableHead={TableHead}
                        selected={selected}
                        setSelected={setSelected}
                        selecttableHead={selecttableHead}
                        handleChange={handleChange}
                        headSelected={headSelected}
                        handleDelete={handleDelete}
                        pagination={
                            <Pagination
                                size="small" 
                                page={page}
                                count={Math.ceil(allShortCodes.length / 10)}
                                onChange={handlePaginationChange} />
                        }
                    >
                        {allShortCodes
                            ?.slice((page - 1) * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            ?.map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <ShortCodesDataTable
                                        key={row.id}
                                        shortCode={row}
                                        deleteShortCode={deleteShortCode}
                                        handleClick={handleClick}
                                        isItemSelected={isItemSelected}
                                        labelId={labelId}
                                        headSelected={headSelected}
                                        data={allShortCodes}
                                    />
                                );
                            })}
                    </NewDataTable>
                ) : (
                    <div className="noData">
                        <p style={{ textAlign: "center" }}>No  Short Codes Found</p>
                        <button
                            className="addButton"
                            onClick={() => {
                                addNewShortCode()
                            }}
                        >
                            <BsPlus size={24} />
                            Add a new Short Code
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default connect(
    (state) => ({
        isPending: state.ShortCodes.isPending,
        allShortCodes: state.ShortCodes.allShortCodes,
    }),
    (dispatch) => ({
        loadShortCodes: () => dispatch(getAllShortCodes()),
        deleteShortCode: (id) => dispatch(deleteShortCode(id)),
    })
)(ShortCodes);

ShortCodes.propTypes = {
    isPending: PropTypes.bool,
    allShortCodes: PropTypes.array,
    loadShortCodes: PropTypes.func,
    deleteShortCode: PropTypes.func,
    history: PropTypes.object,
};
