import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ActivityDataTable from "../../components/ActivityDataTable/ActivityDataTable";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { deleteActivities, loadActivitiesList } from "../../reducers/Activities";

import "../../assets/styles/base.scss";
import NewDataTable from "../../components/NewDataTable";
import { BsPlus } from "react-icons/bs";
import { Pagination } from "@material-ui/lab";
import toaster from "../../modules/toaster";

const selecttableHead = [
  "id",
  "intID",
  "nameAR",
  "nameEN",
  "infoAR",
  "infoEN",
  "order",
  "countryid",
  "Deeplink",
  "Status",
  "Actions",
];

const rowsPerPage = 10;

const Activities = ({
  isPending,
  allActivities,
  loadAllActivities,
  history,
  deleteActivities,
  state
}) => {
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [headSelected, setHeadSelected] = React.useState({
    id: false,
    intID: false,
    nameAR: true,
    nameEN: false,
    infoAR: false,
    infoEN: false,
    order: false,

    countryid: false,
    Deeplink: false,

    Status: true,
    Actions: true,
  });
  const [lastDeleted, setLastDeleted] = useState();
  const [activitiesIDs, setActivitiesIDs] = useState({
    activitiesIDs: selected,
  });
  function getKeyByValue(object, value) {
    return Object.keys(object).filter((key) => object[key] === value);
  }
  const TableHead = getKeyByValue(headSelected, true);

  const handleChange = (event) => {
    setHeadSelected({
      ...headSelected,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    loadAllActivities();
  }, [allActivities,lastDeleted,state]);
  const addNewActivity = () => {
    history.push("addNewActivity");
  };
  const handleDelete = () => {
    if(activitiesIDs.activitiesIDs.length===0){
      toaster.warning('please select some activities')
    }else{
   
    deleteActivities(activitiesIDs).then(() => {
      setLastDeleted(activitiesIDs);
    });
    loadAllActivities();
    setSelected([])
    setLastDeleted(null)
    }

  };

  if (isPending) {
    return <LoadingSpinner />;
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    setActivitiesIDs({ activitiesIDs: newSelected });

  };

  const handlePaginationChange = (event, value) => {
    setPage(value);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <React.Fragment>
      <div className="header">
        <h4 className="title">Activities</h4>
      </div>
      <div className="card">
        {allActivities.length > 0 ? (
          <NewDataTable
            title={"Add an Activity"}
            csvTitle={"Activity"}
            add={addNewActivity}
            data={allActivities}
            tableHead={TableHead}
            selected={selected}
            setSelected={setSelected}
            selecttableHead={selecttableHead}
            handleChange={handleChange}
            headSelected={headSelected}
            handleDelete={handleDelete}
            pagination={
              <Pagination 
              // size="small" 
              page={page}
              count={Math.ceil((allActivities.length/10))}
              // total={projects.length} 
              onChange={handlePaginationChange} />
            }
          >
            {allActivities
              ?.slice((page-1) * rowsPerPage, (page-1) * rowsPerPage + rowsPerPage)
              ?.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <ActivityDataTable
                    data={row}
                    isItemSelected={isItemSelected}
                    labelId={labelId}
                    handleClick={handleClick}
                    key={row.id}
                    org={row}
                    isPending={isPending}
                    headSelected={headSelected}
                  />
                );
              })}
          </NewDataTable>
        ) : (
          <div className="noData">
            <p style={{ textAlign: "center" }}>No activities Found</p>
            <button
              className="addButton"
              onClick={() => {
                addNewActivity
              }}
            >
              <BsPlus size={24} />
              Add A new activity
            </button>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default connect(
  (state) => ({
    isPending: state.Activities.isPending,
    allActivities: state.Activities.activities,
    state:state.Activities
  }),
  (dispatch) => ({
    loadAllActivities: () => dispatch(loadActivitiesList()),
    deleteActivities:(activitiesIDs)=>dispatch(deleteActivities(activitiesIDs))
  })
)(Activities);
