import { config } from "../config";

const shortCodesPending = "SHORT_CODES_PENDING";
const createShortCodes = "CREATE_SHORT_CODES";
const getShortCodes = "GET_SHORT_CODES";
const getShortCode = "getShortCode";
const editShortCodes = "EDIT_SHORT_CODES";
const deleteShortCodes = "DELETE_SHORT_CODES";
const shortCodesError = "SHORT_CODES_ERROR";

const endpointShortCode = "shortcodes";

const initialState = {
    allShortCodes: [],
    singleShortCode: {},
    error: null,
    isPending: false,
    loaded: false,
};

// Create New Short Code
export const createNewShortCode = (newShortCode) => async (dispatch) => {
    dispatch({
        type: shortCodesPending,
        payload: true,
    });

    try {
        const response = await fetch(`${config.ikhairstg}/${endpointShortCode}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(newShortCode),
        });
        const data = await response.json();
        dispatch({
            type: createShortCodes,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: shortCodesError,
            payload: error.message, 
        });
    }
};

// Get All Short Code
export const getAllShortCodes = () => async (dispatch) => {
    dispatch({
        type: shortCodesPending,
        payload: true,
    });
    try {
        const response = await fetch(`${config.ikhairstg}/${endpointShortCode}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const data = await response.json();
        // console.log(data);
        dispatch({
            type: getShortCodes,
            payload: data.shortcodes,
        });
    } catch (error) {
        dispatch({
            type: shortCodesError,
            payload: error.message,
        });
    }
};

export const getSingleShortCode = (shortCodeId) => async (dispatch) => {
    dispatch({
        type: shortCodesPending,
        payload: true,
    });
    try {
        const response = await fetch(
            `${config.ikhairstg}/${endpointShortCode}/${shortCodeId}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const data = await response.json();
        dispatch({
            type: getShortCode,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: shortCodesError,
            payload: error.message,
        });
    }
};

// Edit Short Code
export const editShortCode = (id, newShortCodeData) => async (dispatch) => {
    dispatch({
        type: shortCodesPending,
        payload: true,
    });
    try {
        const response = await fetch(
            `${config.ikhairstg}/${endpointShortCode}/${id}`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(newShortCodeData),
            }
        );
        const data = await response.json();
        dispatch({
            type: editShortCodes,
            payload: { id, newShortCodeData },
        });
    } catch (error) {
        dispatch({
            type: shortCodesError,
            payload: error.message,
        });
    }
};

// Delete Short Code
export const deleteShortCode = (id) => async (dispatch) => {
    dispatch({
        type: shortCodesPending,
        payload: true,
    });
    try {
        const response = await fetch(
            `${config.ikhairstg}/${endpointShortCode}/${id}`,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const data = await response.json();
        dispatch({
            type: deleteShortCodes,
            payload: id,
        });
    } catch (error) {
        dispatch({
            type: shortCodesError,
            payload: error.message,
        });
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case shortCodesPending: {
            return {
                ...state,
                isPending: true 
            };
        }
        case shortCodesError:
            return {
                ...state,
                isPending: false,
                loaded: true,
                error: action.payload,
            };
        case createShortCodes:
            return {
                ...state,
                allShortCodes: [...state.allShortCodes, action.payload],
                isPending: false,
                loaded: true,
            };

        case getShortCodes:
            return {
                ...state,
                allShortCodes: action.payload,
                isPending: false,
                loaded: true,
            };

        case editShortCodes:
            return {
                ...state,
                allShortCodes: state.allShortCodes?.map((code) =>
                    code.id === action.payload.id ? action.payload.newShortCodeData : code
                ),
                isPending: false,
                loaded: true,
            };

        case deleteShortCodes:
            return {
                ...state,
                allShortCodes: state.allShortCodes.filter(
                    (code) => code.id !== action.payload
                ),
                isPending: false,
                loaded: true,
            };

        case getShortCode:
            return {
                ...state,
                singleShortCode: action.payload,
                isPending: false,
                loaded: true,
            };

        default:
            return state;
    }
};

export default reducer;
