import { Grid } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Button as AntdButton, Modal } from "antd";
import axios from "axios";
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { convert } from "html-to-text";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Button, FormControl, ToggleButton } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { FaWhatsapp } from "react-icons/fa";
import { connect } from "react-redux";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { config } from "../../config.js";
import toaster from "../../modules/toaster";
import {
  createInMemoryPerson,
  editInMemoryPerson,
  getInMemoryPerson,
  uploadPersonPhoto,
} from "../../reducers/InMemory.js";
import "./AddInMemoryOf.css";
import rawLandingPageTemplate from "./landingPage.html";

const AddInMemoryOf = ({
  history,
  inMemoryPerson,
  inMemoryPersonImage,
  getInMemoryPerson,
  editInMemoryPerson,
  createInMemoryPerson,
  uploadPersonPhoto,
  activities,
  orgs,
}) => {
  const personId =
    window.location.hash.includes("editInMemory") &&
    window.location.hash.split("/")[window.location.hash.split("/").length - 1];
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [name, setName] = useState("");

  const [age, setAge] = useState(0);
  const [mobileNum, setMobileNum] = useState("");

  const [actId, setActId] = useState("");
  const [orgId, setOrgId] = useState("");
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [selectedImage, setSelectedImage] = useState("");
  const [imageURL, setImageURL] = useState(
    "https://cdn.appgain.io/5ce15857bbab6b001018eed0/ikharstga/hC2L7.jpg"
  );
  const [applicantName, setApplicantName] = useState("");
  const [userId, setUserId] = useState("");
  const [language, setLanguage] = useState("AR");
  const [gender, setGender] = useState("male");

  const [isApproved, setIsApproved] = useState(true);

  const [isDescriptionRequired, setIsDescriptionRequired] = useState(false);
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(true);
  const [openLanguage, setOpenLanguage] = useState(false);
  const [openGender, setOpenGender] = useState(false);

  const [openAct, setOpenAct] = useState(false);
  const [openOrg, setOpenOrg] = useState(false);
  const [error, setError] = useState("");

  const fileInputRef = useRef();

  const [image, setImage] = useState();
  const [preview, setPreview] = useState();
  const [targetAmount, setTargetAmount] = useState(0);
  const [landingPageData, setLandingPageData] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const [shareLink, setShareLink] = useState("");

  const storeImageHandler = (e) => {
    e.preventDefault();
    setSelectedImage(e.target.files[0]);
    const file = e.target.files[0];

    setImage(file);
  };

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(image);
    } else {
      setPreview(null);
    }
  }, [image]);


  const uploadImageHandler = async () => {
    const fd = new FormData();
    fd.append("photo", selectedImage);

    try {
      const result = await uploadPersonPhoto(fd);
      if (result && result.imageURL) {
        setImageURL(result.imageURL);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };



  useEffect(() => {
    if (personId) {
      getInMemoryPerson(personId).then(() => {
        setLoading(false);
      });
      setIsEditingMode(true);
    } else {
      setIsEditingMode(false);
      setLoading(false);
    }
  }, [personId]);

  const createLandingPage = async () => {
    const apiLink = `${config.appgainServer}/apps/${config.suitId}/landingpages`;
    const apiKey = config.appApiKey;

    try {
      const response = await axios.post(
        `${config.appBackend}/functions/getInMemoryDetails`,
        { objectId: personId },
        {
          headers: {
            "X-Parse-Application-Id": config.appId,
          },
        }
      );

      const sanitizeTemplate = {
        "NGO-link": response?.data?.result?.organization?.web || "",
        "NGO-logo-link":
          `https://cdn.ikhair.net/ikhair/images/organziations/${response?.data?.result?.organization?.intID}-214.png` ||
          "",
        project: response?.data?.result?.projectDetails?.nameAR || "",
        keyword: response?.data?.result?.keyword[0]?.keywordEN || "",
        name: name,
        description: convert(stateToHTML(description.getCurrentContent())),
        shortcodesData: JSON.stringify(
          response?.data?.result?.shortCodes || {}
        ),
      };

      let finalTemplate = rawLandingPageTemplate;

      Object.entries(sanitizeTemplate).forEach(([key, value]) => {
        finalTemplate = finalTemplate.replace(
          new RegExp(`{{${key}}}`, "g"),
          value
        );
      });

      const descriptionText = convert(
        stateToHTML(description.getCurrentContent())
      );

      const sendingData = {
        name: name,
        label: name,
        lang: "en",
        socialmedia_settings: {
          title: `${name} صدقة جارية علي روح `,
          description: descriptionText,
        },
        tag: "inMemory",
        web_push_subscription: true,
        image_default: true,
        theme: "code_embed",
        FBpixel_tracking: true,
        twitter_pixel_tracking: false,
        google_ads_pixel_tracking: false,
        linkedIn_pixel_tracking: false,
        snapchat_pixel_tracking: false,
        tiktok_pixel_tracking: false,
        show_in_defPage: false,
        components: [
          {
            type: "basic.h+logo",
          },
          {
            type: "basic.p",
          },
          {
            type: "basic.code_embded_component",
            src: finalTemplate,
          },
        ],
      };

      const sendToAnotherAPIResponse = await axios.post(apiLink, sendingData, {
        headers: {
          "X-Parse-Application-Id": config.appId,
          appApiKey: apiKey,
        },
      });

      setLandingPageData(sendToAnotherAPIResponse.data);
      setShareLink(sendToAnotherAPIResponse.data?.link);
      setIsModalOpen(true);

      // TODO: send created MP with SMS
    } catch (error) {
      toaster.error("Something went wrong While getting in Memory Data");
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const submitInMemoryPersonDataHandler = async (e) => {
    e.preventDefault();

    if (description.getCurrentContent().getPlainText().trim().length <= 0) {
      setIsDescriptionRequired(true);
      return;
    }
    const inMemoryPersonData = {
      name,
      description: stateToHTML(description.getCurrentContent()),
      image: imageURL,
      age: parseInt(age),
      actId,
      mobileNum,
      orgId,
      sdl: shareLink,
      userId,
      Approved: isApproved,
      language,
      gender,
      applicantName,
      targetAmount: Number(targetAmount),
    };

    try {
      setLoading(true);
      if (isEditingMode) {
        delete inMemoryPersonData["mobileNum"];
        await editInMemoryPerson(personId, inMemoryPersonData);
        setLoading(false);
        // window.location.reload();
      } else {
        // creating new in memory
        if (!_.isEmpty(actId) && !_.isEmpty(orgId)) {
          await createInMemoryPerson(inMemoryPersonData);

          history.push("/inMemory");
        } else {
          setError("Act id and Organization id are required");
          return;
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedImage) {
      if (selectedImage.size > 512000) {
        setShow(true);
        setSelectedImage("");
      }
    }
    // if (inMemoryPersonImage) {
    //   setImageURL(inMemoryPersonImage);
    // } else if (!inMemoryPersonImage & (gender === "female")) {
    //   setImageURL(
    //     "https://cdn.appgain.io/5ce15857bbab6b001018eed0/ikharstga/tNQup.jpg"
    //   );
    // } else if (!inMemoryPersonImage & (gender === "male")) {
    //   setImageURL(
    //     "https://cdn.appgain.io/5ce15857bbab6b001018eed0/ikharstga/hC2L7.jpg"
    //   );
    // }
  }, [selectedImage, inMemoryPersonImage, gender]);

  useEffect(() => {
    if (personId) {
      setName(inMemoryPerson?.name);
      setDescription(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(
              inMemoryPerson.description
                ? inMemoryPerson.description
                : `<p>No Description</p>`
            )
          )
        )
      );

      setImageURL(inMemoryPerson.image);
      if (
        inMemoryPerson.mobileNum === null ||
        inMemoryPerson.mobileNum === "null"
      ) {
        setMobileNum("");
      } else {
        setMobileNum(`+971${inMemoryPerson.mobileNum}`);
      }
      setAge(inMemoryPerson.age);
      setUserId(inMemoryPerson.userId);
      setGender(inMemoryPerson.gender);
      setShareLink(inMemoryPerson.sdl);
      if (inMemoryPerson.language) {
        setLanguage(inMemoryPerson.language);
      }
      setActId(inMemoryPerson.actId);
      setOrgId(inMemoryPerson.orgId);
      setIsApproved(inMemoryPerson.Approved);
      setApplicantName(inMemoryPerson.applicantName);
      setTargetAmount(inMemoryPerson.targetAmount);
      setEdit(true);
    }
  }, [
    inMemoryPerson,
    inMemoryPerson?.actId,
    inMemoryPerson?.orgId,
    inMemoryPerson?.gender,
  ]);

  useEffect(() => {
    console.log("inMemoryPerson");
  }, [inMemoryPerson]);

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };
  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  const handleOrgIdChange = (e) => {
    setOrgId(e.target.value);
  };

  const visitLandingPage = () => {
    window.open(landingPageData?.link, "_blanck");
  };
  const handleOk = () => {
    window.open(landingPageData?.link, "_blanck");
    setIsModalOpen(false);
    // history.push("/inMemory");
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    // history.push("/inMemory");
  };

  return (
    <div>
      {loading && <LoadingSpinner />}
      <div className="header">
        <h4 className="title">
          {isEditingMode
            ? `Edit inMemoryPerson for: ${name}`
            : "Add a New inMemoryPerson"}
        </h4>
      </div>
      <form
        className="card"
        style={{ padding: "12px 24px" }}
        onSubmit={submitInMemoryPersonDataHandler}
      >
        <Grid container spacing={3} style={{ padding: "15px 28px" }}>
          <Grid item md={6} sm={12} className="form-group">
            <label id="personId" className="control-label">
              Person id(optional)
            </label>

            <FormControl
              type="text"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              name="personId"
              htmlFor="personId"
              placeholder="Enter id"
            />
          </Grid>
          <Grid item md={6} sm={12} className="form-group">
            <label id="personName" className="control-label">
              Person Name
            </label>
            <FormControl
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              name="personName"
              htmlFor="personName"
              placeholder="Enter Name"
              required
            />
          </Grid>

          <Grid item md={6} sm={12} className="form-group">
            <label id="gender" className="control-label">
              Gender
            </label>
            <Select
              className="newProjectSelect"
              labelId="gender"
              id="gender"
              open={openGender}
              onClose={() => setOpenGender(false)}
              onOpen={() => setOpenGender(true)}
              value={gender}
              onChange={handleGenderChange}
              style={{ padding: "8px 10px" }}
            >
              <MenuItem value="male">male</MenuItem>
              <MenuItem value="female">female</MenuItem>
            </Select>
          </Grid>

          <Grid item md={6} sm={12} className="form-group">
            <label id="personAge" className="control-label">
              Person Age
            </label>
            <FormControl
              type="number"
              value={age}
              onChange={(e) => setAge(e.target.value)}
              name="personAge"
              htmlFor="personAge"
              placeholder="Enter Age"
              required
            />
          </Grid>
          <Grid item md={6} sm={12} className="form-group">
            <label htmlFor="mobileNum" className="control-label">
              Phone Number
            </label>
            <div className="whatsappLink">
              <FormControl
                type="text"
                value={mobileNum}
                onChange={(e) => setMobileNum(e.target.value)}
                name="mobileNum"
                id="mobileNum"
                placeholder="Mobile Number"
                readOnly={isEditingMode}
              />
              {isEditingMode && inMemoryPerson.mobileNum && (
                <a
                  href={`https://wa.me/${mobileNum}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaWhatsapp />
                </a>
              )}
            </div>
          </Grid>
          <Grid item sm={12} className="form-group">
            <label id="personDescription" className="control-label">
              Description
            </label>

            <Editor
              editorState={description}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={(e) => setDescription(e)}
              onFocus={() => setIsDescriptionRequired(false)}
              onBlur={() =>
                description.getCurrentContent().getPlainText().trim().length <=
                0 && setIsDescriptionRequired(true)
              }
            />
            {isDescriptionRequired && (
              <p
                style={{
                  color: "red",
                  textAlign: "center",
                  fontWeight: 700,
                }}
              >
                Description field is required
              </p>
            )}
          </Grid>
          <Grid item sm={12}>
            <Grid container spacing={3}>
              <Grid item md={4} sm={6} className="form-group">
                <label id="language" className="control-label">
                  Language
                </label>

                <Select
                  className="newProjectSelect"
                  labelId="language"
                  id="languages"
                  open={openLanguage}
                  onClose={() => setOpenLanguage(false)}
                  onOpen={() => setOpenLanguage(true)}
                  value={language}
                  onChange={handleLanguageChange}
                  style={{ padding: "8px 10px" }}
                >
                  <MenuItem value="AR">ar</MenuItem>
                  <MenuItem value="EN">en</MenuItem>
                </Select>
              </Grid>
              <Grid item md={4} sm={6} className="form-group">
                <label id="actId" className="control-label">
                  Act id
                </label>
                <Select
                  className="newProjectSelect"
                  labelId="actId"
                  id="actId"
                  open={openAct}
                  onClose={() => setOpenAct(false)}
                  onOpen={() => setOpenAct(true)}
                  value={actId}
                  onChange={(e) => setActId(e.target.value)}
                  style={{ padding: "8px 10px" }}
                >
                  {activities?.map((act) => (
                    <MenuItem key={act.intID} value={act.intID}>
                      {act[`name${language?.toLocaleUpperCase()}`]}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item md={4} sm={6} className="form-group">
                <label id="orgId" className="control-label">
                  Organization id
                </label>
                <Select
                  className="newProjectSelect"
                  labelId="orgId"
                  id="orgId"
                  open={openOrg}
                  onClose={() => setOpenOrg(false)}
                  onOpen={() => setOpenOrg(true)}
                  value={orgId}
                  onChange={handleOrgIdChange}
                  style={{ padding: "5px 10px" }}
                >
                  {orgs?.map((org) => (
                    // Print the name depending on the language
                    <MenuItem key={org.intID} value={org.intID}>
                      {org[`name${language?.toLocaleUpperCase()}`]}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item md={4} sm={6} className="form-group">
                <label id="applicantName" className="control-label">
                  Applicant Name
                </label>

                <FormControl
                  type="text"
                  value={applicantName}
                  onChange={(e) => setApplicantName(e.target.value)}
                  name="applicantName"
                  htmlFor="applicantName"
                  placeholder="Enter Applicant Name"
                  required
                />
              </Grid>
              <Grid item md={4} sm={6} className="form-group">
                <label id="targetAmount" className="control-label">
                  target Amount
                </label>

                <FormControl
                  type="number"
                  value={targetAmount}
                  onChange={(e) => setTargetAmount(e.target.value)}
                  name="targetAmount"
                  htmlFor="targetAmount"
                  placeholder="Enter Target Amount"
                  required
                />
              </Grid>
              <Grid item md={4} sm={12} className="form-group">
                <label id="shareLink">share link</label>
                <FormControl
                  type="text"
                  value={shareLink}
                  onChange={(e) => setShareLink(e.target.value)}
                  name="shareLink"
                  htmlFor="shareLink"
                  placeholder="Enter Link"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item sm={12}>
            <div
              className="form-group"
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <ToggleButton
                className="mb-2 checkboxButton"
                id="toggle-check"
                type="checkbox"
                checked={isApproved}
                onChange={() => setIsApproved((preValue) => !preValue)}
              >
                <span className="" style={{ marginLeft: "5px" }}>
                  {isApproved ? "Approved" : "Not Approved"}
                </span>
              </ToggleButton>
            </div>
          </Grid>
          {isEditingMode && (
            <Button
              disabled={!edit}
              className="create-inMemory-btn"
              type="button"
              onClick={async () => {
                setLoading(true);
                await createLandingPage();
                setLoading(false);
              }}
            >
              Generate Landing Page
            </Button>
          )}

          <Grid item sm={12}>
            <div className="fieldDiv">
              <label>
                {isEditingMode ? "Change" : "Upload"} Person Image
                <img
                  // src={preview || imageURL}
                  src={imageURL || preview}
                  alt="default"
                  style={{ width: "124px", height: "124px", display: "block" }}
                />
              </label>
              <div className="file-input">
                <input
                  style={{ width: "48px", cursor: "pointer" }}
                  type="file"
                  accept="image/*"
                  placeholder="Choose Person Image"
                  onChange={storeImageHandler}
                  className="file"
                  id="file"
                  ref={fileInputRef}
                />
                <button
                  htmlFor="file"
                  className="uploadBtn"
                  onClick={(event) => {
                    event.preventDefault();
                    fileInputRef.current.click();
                  }}
                >
                  {selectedImage || isEditingMode ? "Change " : "Select "}
                </button>
                {/* <p className="file-name"></p> */}
                <button
                  className="removeBtn"
                  type="button"
                  onClick={() => {
                    setSelectedImage(null);
                    setPreview(null);
                  }}
                >
                  Remove
                </button>
              </div>
              <div style={{ marginTop: "20px" }}>
                {selectedImage && (
                  <React.Fragment>
                    {!imageURL && <p>Please Upload the image</p>}

                    <button
                      style={{ marginTop: ".4em" }}
                      onClick={uploadImageHandler}
                      className="uploadBtn"
                    >
                      Upload Image
                    </button>
                  </React.Fragment>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
        <div className="submit">
          <Button
            disabled={!edit}
            className="create-edit-form-btn"
            type="submit"
          >
            {isEditingMode ? "Edit" : "Submit"}
          </Button>
        </div>
        <div className="error">
          <p>{error}</p>
        </div>
      </form>
      {show && (
        <ConfirmModal
          handleClose={() => setShow(false)}
          handleShow={() => setShow(true)}
          message={`Oops! Image size is more than 500 kilobyte! Please Choose smaller size image`}
          setShow={setShow}
          show={show}
        ></ConfirmModal>
      )}

      <Modal
        title="successfully Created"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
      >
        <h3>Successfully created the landing page</h3>
        <AntdButton onClick={visitLandingPage}>
          Visit the Landing page
        </AntdButton>
        <div
          style={{
            paddingTop: "20px",
          }}
        >
          Click on Edit button To save the landing page link in the share Link{" "}
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  inMemoryPerson: state.InMemory.inMemoryPerson,
  inMemoryPersonImage: state.InMemory.inMemoryPersonImage,
  activities: state.Activities.activities,
  orgs: state.ORGs.orgs,
});

const actions = (dispatch) => ({
  getInMemoryPerson: (personId) => dispatch(getInMemoryPerson(personId)),
  editInMemoryPerson: (personId, newInMemoryPersonData) =>
    dispatch(editInMemoryPerson(personId, newInMemoryPersonData)),
  uploadPersonPhoto: (image) => dispatch(uploadPersonPhoto(image)),
  createInMemoryPerson: (newInMemoryPersonData) =>
    dispatch(createInMemoryPerson(newInMemoryPersonData)),
});

export default connect(mapStateToProps, actions)(AddInMemoryOf);
