import _ from "lodash";
import networkService from "../api.js";
import uploadOnS3 from "../helpers/uploadOnS3";
import toaster from "../modules/toaster.js";

const defaultState = {
  orgs: [],
  error: null,
  isPending: null,
  loaded: false,
  selectedOrg: null,
  singleOrg: {},
  orgWithId: [],
  orgImage: "",
};
const LOAD_ORGS_LIST = "LOAD_ORGS_LIST";
const LOAD_ORGS_LIST_PENDING = "LOAD_ORGS_LIST_PENDING";
const LOAD_ORGS_LIST_ERROR = "LOAD_ORGS_LIST_ERROR";
const SET_SELECTED_ORG = "SET_SELECTED_ORG";
const ORG_WITH_ID = "ORG_WITH_ID";
const EDIT_ORG = "EDIT_ORG";
const DELETE_ORG = "DELETE_ORG";
const GET_SINGLE_ORG = "GET_SINGLE_ORG";
const CREATE_ORG = "CREATE_ORG";
const ORG_IMAGE = "ORG_IMAGE";
const DELETE_ORGS="DELETE_ORGS"

export const setSelectedOrg = (org) => {
  return {
    type: SET_SELECTED_ORG,
    selectedOrg: org,
  };
};

export const loadORGsList = () => (dispatch, getState) => {
  if (!getState().ORGs.loaded) {
    dispatch({ type: LOAD_ORGS_LIST_PENDING });
    networkService
      .send("ngos")
      .then((res) => {
        dispatch({ type: LOAD_ORGS_LIST, orgs: res.data.organizations });
        dispatch(orgWithId());
      })
      .catch((error) => {
        dispatch({ type: LOAD_ORGS_LIST_ERROR, error });
      });
  }
};

export const orgWithId = () => (dispatch, getState) => {
  const orgs = getState().ORGs.orgs;
  const payload = _.filter(
    orgs,
    (org) => org.takamul_ngo_id && org.takamul_ngo_id !== "0"
  );
  dispatch({ type: "ORG_WITH_ID", payload });
};

export const createOrg = (data) => async (dispatch) => {
  dispatch({ type: LOAD_ORGS_LIST_PENDING });
  try {
    await networkService.send("createOrg", data);
    dispatch({ type: CREATE_ORG });
  } catch (error) {
    dispatch({ type: LOAD_ORGS_LIST_ERROR, error });
  }
};

export const editOrgStatus = (data) => async (dispatch) => {
  dispatch({ type: LOAD_ORGS_LIST_PENDING });
  try {
    await networkService.send("orgStatus", data);
    dispatch({ type: EDIT_ORG });
  } catch (error) {
    dispatch({ type: LOAD_ORGS_LIST_ERROR, error });
  }
};

export const editOrg = (data) => async (dispatch) => {
  dispatch({ type: LOAD_ORGS_LIST_PENDING });
  try {
    await networkService.send("editOrg", data);
    dispatch({ type: EDIT_ORG });
  } catch (error) {
    dispatch({ type: LOAD_ORGS_LIST_ERROR, error });
  }
};

export const deleteOrg = (id) => async (dispatch) => {
  dispatch({ type: LOAD_ORGS_LIST_PENDING });
  try {
    await networkService.send("deleteOrg", id);
    dispatch({ type: DELETE_ORG });
  } catch (error) {
    dispatch({ type: LOAD_ORGS_LIST_ERROR, error });
  }
};
export const deleteOrgs = (organizationsIDs) => async (dispatch) => {
  dispatch({ type: LOAD_ORGS_LIST_PENDING });
  try {
    await networkService.send("deleteOrgs", organizationsIDs);
    toaster.success('successfully deleted otganizations')

    dispatch({ type: DELETE_ORGS });
  } catch (error) {
    dispatch({ type: LOAD_ORGS_LIST_ERROR, error });
    toaster.error(error)
  }
};

export const getSingleOrg = (id) => async (dispatch) => {
  dispatch({ type: LOAD_ORGS_LIST_PENDING });
  try {
    let res = await networkService.send("getSingleOrg", { id });
    dispatch({ type: GET_SINGLE_ORG, payload: res.data.organization });
  } catch (error) {
    dispatch({ type: LOAD_ORGS_LIST_ERROR, error });
  }
};

export const uploadOrgImage = (image, orgId) => async (dispatch) => {
  dispatch({ type: LOAD_ORGS_LIST_PENDING, payload: true });

  try {
    const data = await uploadOnS3(image, `${orgId}-214`, "organziations");
    dispatch({ type: ORG_IMAGE, payload: data.Location });
  } catch (error) {
    dispatch({ type: LOAD_ORGS_LIST_ERROR, payload: error });
  }
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case LOAD_ORGS_LIST:
      return {
        ...state,
        isPending: false,
        error: false,
        loaded: true,
        orgs: action.orgs,
      };
    case LOAD_ORGS_LIST_PENDING:
      return {
        ...state,
        isPending: true,
        error: false,
        loaded: true,
      };
    case LOAD_ORGS_LIST_ERROR:
      return {
        ...state,
        isPending: false,
        error: action.error,
        loaded: false,
      };
    case ORG_WITH_ID:
      return {
        ...state,
        orgWithId: action.payload,
      };

    case CREATE_ORG:
      return {
        ...state,
        isPending: false,
        error: false,
        loaded: false,
      };

    case SET_SELECTED_ORG:
      return {
        ...state,
        selectedOrg: action.selectedOrg,
      };

    case EDIT_ORG:
      return {
        ...state,
        isPending: false,
        error: false,
        loaded: false,
      };

    case DELETE_ORG:
      return {
        ...state,
        isPending: false,
        error: false,
        loaded: false,
      };
      case DELETE_ORGS:
        return {
          ...state,
          isPending: false,
          error: false,
          loaded: false,
        };
    case GET_SINGLE_ORG:
      return {
        ...state,
        singleOrg: action.payload,
        isPending: false,
        error: false,
        loaded: false,
      };

    case ORG_IMAGE:
      return {
        ...state,
        orgImage: action.payload,
        isPending: false,
        loaded: true,
      };
    default:
      return state;
  }
}
