/* eslint-disable no-undef */
import Checkbox from "@material-ui/core/Checkbox";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Switch from "components/Switch";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { BiCopy } from "react-icons/bi";
import { FaRegEdit } from "react-icons/fa";
import { connect } from "react-redux";
import "../../../assets/styles/base.scss";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import SelectOrg from "../../../components/SelectOrg";
import toaster from "../../../modules/toaster";
import {
  duplicateCase,
  editCaseStatus,
  loadCasesList,
} from "../../../reducers/Cases";
import generateData from "../generateData";

class TableWithSwitch extends Component {
  state = {
    items: generateData(),
    show: false,
  };

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  getActivityName = (id) => {
    let name = id;
    if (this.props.activities && this.props.activities.length > 0)
      for (let act of this.props.activities)
        if (act.id == id) name = act.nameAR;
    return name;
  };

  getNGOName = (id) => {
    let name = id;
    if (this.props.orgs && this.props.orgs.length > 0)
      for (let org of this.props.orgs) if (org.id == id) name = org.nameAR;
    return name;
  };

  toggleActive = (urgentCase) => {
    this.props
      .editStatus({
        id: urgentCase.id,
        new_status: urgentCase.isactive == 1 ? 0 : 1,
      })
      .then(() => {
        urgentCase.isactive = urgentCase.isactive == 1 ? 0 : 1;
        this.props.loadCases(this.props.selectedOrg);

        toaster.success("Case's status has been changed successfully.");
      });
  };

  nextPath(path) {
    this.context.router.history.push(path);
  }

  handleDuplicate = (urgentCase) => {
    if (this.props.selectedOrg == null) {
        toaster.error('You must select an organization before duplicating the case')
        this.setState({show:true})
    } else {
      this.props.duplicate(urgentCase).then(() => {
        this.nextPath("/urgent/new?duplicate=true");
      });
    }
  };

  handleEdit = (urgentCase) => {
    this.props.duplicate(urgentCase).then(() => {
      this.nextPath("/urgent/new?edit=true");
    });
  };

  render() {
    return (
      <React.Fragment>
        <TableRow
          hover
        
          aria-checked={this.props.isItemSelected}
          tabIndex={-1}
          key={this.props.case.id}
          selected={this.props.isItemSelected}
        >
          <TableCell padding="checkbox">
            <Checkbox
              onClick={(event) => this.props.handleClick(event, this.props.case.id)}
              role="checkbox"
              checked={this.props.isItemSelected}
              inputProps={{ "aria-labelledby": this.props.labelId }}
            />
          </TableCell>
          {this.props.headSelected.ID && (
            <TableCell>{this.props.case.id}</TableCell>
          )}
          {this.props.headSelected.Category && (
            <TableCell>{this.getActivityName(this.props.case.actid)}</TableCell>
          )}
          {this.props.headSelected.ORG && (
            <TableCell>{this.getNGOName(this.props.case.orgid)}</TableCell>
          )}
          {this.props.headSelected.NameAR && (
            <TableCell>{this.props.case.nameAR}</TableCell>
          )}
           
   {this.props.headSelected.nameEN && (
            <TableCell>{this.props.case.nameEN}</TableCell>
          )}
           {this.props.headSelected.DescriptionAR && (
            <TableCell dangerouslySetInnerHTML={{ __html: this.props.case.infoAR }}></TableCell>
          )}
          {this.props.headSelected.DescriptionEN && (
            <TableCell dangerouslySetInnerHTML={{ __html: this.props.case.infoEN }}></TableCell>
          )}
          {this.props.headSelected.TargetAmount && (
            <TableCell>{this.props.case.targetAmount}</TableCell>
          )}
          {this.props.headSelected.collectedAmount && (
            <TableCell>{this.props.case.collectedAmount}</TableCell>
          )}
          {this.props.headSelected.Deeplink && (
            <TableCell>
              ikhair://screen?target=cause_{this.props.case.id}
              <BiCopy
                size={16}
                color="#B9B9C7"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `ikhair://screen?target=cause_${this.props.case.id}`
                  );
                }}
              />
            </TableCell>
          )}
          {this.props.headSelected.MediaURL && (
            <TableCell>{this.props.case.mediaURL}</TableCell>
          )}
            {this.props.headSelected.order && (
            <TableCell>{this.props.case.order}</TableCell>
          )}
          {this.props.headSelected.Status && (
            <TableCell>
              <Switch
                value={this.props.case.isactive == 1 ? true : false}
                onText={"Active"}
                offText={"OFF"}
                onChange={() => this.toggleActive(this.props.case)}
              />
            </TableCell>
          )}
          {this.props.headSelected.Actions && (
            <TableCell >
              <div className="TableIcons">

              <FaRegEdit
                cursor="pointer"
                onClick={() => {
                  this.handleEdit(this.props.case);
                }}
                size={24}
                color="#00AEEF"
              />
              <BiCopy
                cursor="pointer"
                onClick={() => {
                  this.handleDuplicate(this.props.case);
                }}
                size={24}
                color="#00AEEF"
              />
              </div>
            </TableCell>
          )}
        </TableRow>
        {this.state.show && (
        <ConfirmModal
          title={"Select organization"}
          handleClose={() => {
            this.setState({show:false})
          }}
          handleShow={() => {
            this.setState({show:true})

          }}
          message={<div className="selectOrg">
            Select organization
            <SelectOrg/>
          </div>}
          show={this.state.show}
        >
          <button
            type="button"
            className="confirmBtn"
            disabled={!this.props.selectedOrg}
            onClick={() => {
              this.setState({show:false})
            }}
          >
            confirm
          </button>
        </ConfirmModal>
      )}
      </React.Fragment>
    );
  }
}

const mapDispatchToProp = (dispatch) => ({
  loadCases: (orgId) => dispatch(loadCasesList(orgId)),
  editStatus: (data) => dispatch(editCaseStatus(data)),
  duplicate: (urgentCase) => dispatch(duplicateCase(urgentCase)),
});

export default connect(
  (state) => ({
    selectedOrg: state.ORGs.selectedOrg,
    cases: state.Cases.cases,
    activities: state.Activities.activities,
    orgs: state.ORGs.orgs,
  }),
  mapDispatchToProp
)(TableWithSwitch);

TableWithSwitch.propTypes = {
  case: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  isItemSelected: PropTypes.bool.isRequired,
  labelId: PropTypes.string.isRequired,
  headSelected: PropTypes.object.isRequired,
  loadCases: PropTypes.func.isRequired,
  editStatus: PropTypes.func.isRequired,
  duplicate: PropTypes.func.isRequired,
  selectedOrg: PropTypes.number,
  activities: PropTypes.array,
  orgs: PropTypes.array,
};
