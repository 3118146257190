import defaultImg from "assets/images/default-banner-mobile.jpg";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Button, FormControl, FormGroup } from "react-bootstrap";
import { connect } from "react-redux";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import {
  createNgo,
  editNgo,
  getNgo,
  uploadNGOImage,
} from "../../reducers/NGOs";

const AddNGONews = (props) => {
  const {
    getNgo,
    ngo,
    history,
    editNgo,
    createNgo,
    isPending,
    uploadImage,
    orgs,
    ngoImage
  } = props;

  const [isEditingMode, setIsEditingMode] = useState(false);
  const [link, setLink] = useState("");
  const [selectedImage, setSelectedImage] = useState();
  const [show, setShow] = useState(false);
  const [orgId, setOrgId] = useState("");
  const fileInputRef = useRef();
  const [selectedLogo, setSelectedLogo] = useState("");
  const [selectedLogoUrl, setSelectedLogoUrl] = useState("");
  const [image, setImage] = useState();
  const [preview, setPreview] = useState();
  const ngoId =
    window.location.hash.includes("editNGONews") &&
    window.location.hash.split("/")[window.location.hash.split("/").length - 1];
  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(image);
    } else {
      setPreview(null);
    }
  }, [image]);

  useEffect(() => {
    if (ngoId) {
      getNgo(ngoId);
      setIsEditingMode(true);
    } else {
      setIsEditingMode(false);
    }
  }, [ngoId]);

  useEffect(() => {
    if (!!ngo && ngoId) {
      // set fields
      setLink(ngo.link);
      setOrgId(ngo.orgId);
      setSelectedLogoUrl(ngo.image);
      setPreview(ngo.image)
    }
  }, [ngo]);

  useEffect(() => {
    if (selectedImage) {
      if (selectedImage.size > 512000) {
        setShow(true);
        setSelectedImage("");
      }
    }
  }, [selectedImage]);

  const handleNgoSubmit = (e) => {
    e.preventDefault();

    const ngoData = {
      link,
      orgId,
      image: ngoImage
    };

    if (isEditingMode) {
      editNgo(ngoId, ngoData);
    } else {
      createNgo(ngoData);
    }
 

    history.push("/ngo");
  };

  const storeImageHandler = (e) => {
    e.preventDefault();

    setSelectedImage(e.target.files[0]);

    const file = e.target.files[0];
    setImage(file);
  };

  // upload image
  const uploadLogoHandler = () => {
   // generating random id based on current unix timestamp
   const id = (Date.now() % 100000).toString(); // get last 5 numbers from the current unix timestamp
   uploadImage(selectedImage, id);
    };
  if (isPending) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <div className="header">
        <h4 className="title">
          {isEditingMode ? `Edit Ngo News` : "Add New Ngo News"}
        </h4>
      </div>
      <form style={{ padding: "12px 24px" }} onSubmit={handleNgoSubmit}  className="card">
        <FormGroup>
          <div className="fieldDiv form-group">
            <label id="link">News link</label>
            <FormControl
              type="text"
              value={link}
              name="link"
              htmlFor="link"
              placeholder="Enter Link for the news"
              onChange={(e) => setLink(e.target.value)}
              required
            />
          </div>
          <div className="fieldDiv form-group">
            <label>Organization</label>
            <select
              onChange={(e) => setOrgId(e.target.value)}
              value={orgId}
              className="newProjectSelect selectInput"
            >
              {!_.isEmpty(orgs) &&
                orgs?.map((org) => (
                  <option key={org.id} value={org.id}>
                    {org.nameEN}
                  </option>
                ))}
            </select>
          </div>
          <div className="fieldDiv form-group">
            <label className="control-label">
              {isEditingMode ? "Change" : "Upload"} Ngo News Image
              <img
                src={preview || defaultImg}
                alt="default"
                style={{ width: "124px", height: "124px", display: "block" }}
              />{" "}
            </label>
            <div className="file-input">
              <input
                style={{ width: "48px" }}
                type="file"
                accept="image/*"
                placeholder="Choose Embassador Image"
                onChange={storeImageHandler}
                className="file"
                id="file"
                ref={fileInputRef}
              />
              <button
                htmlFor="file"
                className="uploadBtn"
                onClick={(event) => {
                  event.preventDefault();
                  fileInputRef.current.click();
                }}
              >
                {selectedImage || isEditingMode ? "Change " : "Select "}
              </button>
              {/* <p className="file-name"></p> */}
              <button
                className="removeBtn"
                type="button"
                onClick={() => {
                  setSelectedImage(null);
                  setPreview(null);
                }}
              >
                Remove
              </button>
            </div>
            <div style={{ marginTop: "20px" }}>
              {selectedImage && (
                <React.Fragment>
                   {!ngoImage&&<p>Please Upload the image</p>}
              
              <button
              style={{marginTop:".4em"}}
                onClick={uploadLogoHandler}
                className="uploadBtn"
                >
                    Upload photo
                  </button>
                </React.Fragment>
              )}
            </div>
          </div>
        </FormGroup>

        <div className="submit">
          <Button
            // disabled={!edit}
            className="create-edit-form-btn"
            type="submit"
          >
            {isEditingMode ? "Edit" : "Submit"}
          </Button>
        </div>
      </form>
      {show && (
        <ConfirmModal
          handleClose={() => setShow(false)}
          handleShow={() => setShow(true)}
          message={`Oops! Image size is more than 500 kilobyte! Please Choose smaller size image`}
          setShow={setShow}
          show={show}
        ></ConfirmModal>
      )}
    </div>
  );
};

export default connect(
  (state) => ({
    ngo: state.NGOs.ngo,
    isPending: state.NGOs.isPending,
    orgs: state.ORGs.orgs,
    ngoImage:state.NGOs.ngoImage
  }),
  (dispatch) => ({
    getNgo: (id) => dispatch(getNgo(id)),
    editNgo: (id, newNgoData) => dispatch(editNgo(id, newNgoData)),
    createNgo: (data) => dispatch(createNgo(data)),
    uploadImage: (image, ngoId) => dispatch(uploadNGOImage(image, ngoId)),
  })
)(AddNGONews);
