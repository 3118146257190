import Checkbox from "@material-ui/core/Checkbox";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import React, { useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "../../assets/styles/base.scss";
import { deleteOrg, editOrgStatus, loadORGsList } from "../../reducers/ORGs";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import Switch from "../Switch";

const OrganizationsDataTable = (props) => {
  const [show, setShow] = useState(false);
  const {
    isItemSelected,
    handleClick,
    labelId,
    org,
    editStatus,
    loadAllORGs,
    deleteOrganization,
    headSelected,
  } = props;

  const openModalHandler = () => setShow(true);

  const handleEditOrgStatus = () => {
    editStatus({
      id: org.id,
      new_status: org.isactive === 1 ? 0 : 1,
    }).then(() => {
      org.isactive = org.isactive === 1 ? 0 : 1;
      loadAllORGs();
    });
  };

  const handleDeleteOrg = () => {
    deleteOrganization({ id: org.id }).then(() => {
      loadAllORGs();
    });
  };

  return (
    <React.Fragment>
      <TableRow
        hover
      
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={org.id}
        selected={isItemSelected}
      >
        <TableCell padding="checkbox">
          <Checkbox
            onClick={(event) => handleClick(event, org.id)}
            role="checkbox"
            checked={isItemSelected}
            inputProps={{ "aria-labelledby": labelId }}
          />
        </TableCell>

        {headSelected.id && <TableCell>{org.id}</TableCell>}

        {headSelected.intID && <TableCell>{org.intID}</TableCell>}
        {headSelected.nameAR && <TableCell>{org.nameAR}</TableCell>}
        {headSelected.nameEN && <TableCell>{org.nameEN}</TableCell>}
        {headSelected.infoAR && <TableCell>{org.infoAR}</TableCell>}
        {headSelected.infoEN && <TableCell>{org.infoEN}</TableCell>}
        {headSelected.order && <TableCell>{org.order}</TableCell>}

        {headSelected.ReportingEmail && (
          <TableCell>{org.ReportingEmail}</TableCell>
        )}
        {headSelected.address && <TableCell>{org.address}</TableCell>}
        {headSelected.countryid && <TableCell>{org.countryid}</TableCell>}
        {headSelected.email && <TableCell>{org.email}</TableCell>}

        {headSelected.fax && <TableCell>{org.fax}</TableCell>}
        {headSelected.fb && <TableCell>{org.fb}</TableCell>}
        {headSelected.tel && <TableCell>{org.tel}</TableCell>}
        {headSelected.tw && <TableCell>{org.tw}</TableCell>}
        {headSelected.web && <TableCell>{org.web}</TableCell>}
        {headSelected.hasRepeatedDonation && (
          <TableCell>{org.hasRepeatedDonation === 1 ? "Yes" : "No"}</TableCell>
        )}
        {headSelected.hasReporting && (
          <TableCell>{org.hasReporting === 1 ? "Yes" : "No"}</TableCell>
        )}
        {headSelected.paymentMethods && (
          <TableCell>{org.paymentMethods}</TableCell>
        )}

        {headSelected.status && (
          <TableCell>
            <Switch
              value={org.isactive}
              offText={"OFF"}
              onText={"Active"}
              onChange={handleEditOrgStatus}
            />
          </TableCell>
        )}
        {headSelected.Actions && (
          <TableCell >
            <div className="TableIcons">

            <Link to={`/editOrganization/${org.id}`}>
              <FaRegEdit size={24} color="#00AEEF" />
            </Link>
            <RiDeleteBinLine
              cursor="pointer"
              onClick={openModalHandler}
              color="#ED1650"
              size={24}
            />
            </div>
          </TableCell>
        )}
      </TableRow>

      {show && (
        <ConfirmModal
          handleClose={() => setShow(false)}
          handleShow={() => setShow(true)}
          message={`Are you sure you want to delete ${org.nameEN} from Organizations?`}
          setShow={setShow}
          show={show}
        >
          <button className="btn btn-danger" onClick={handleDeleteOrg}>
            Delete
          </button>
        </ConfirmModal>
      )}
    </React.Fragment>
  );
};

const mapDispatchToProp = (dispatch) => ({
  editStatus: (data) => dispatch(editOrgStatus(data)),
  loadAllORGs: () => dispatch(loadORGsList()),
  deleteOrganization: (org_id) => dispatch(deleteOrg(org_id)),
});

export default connect(() => ({}), mapDispatchToProp)(OrganizationsDataTable);
