import Checkbox from "@material-ui/core/Checkbox";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import React, { useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
// import dateFormatter from "../../helpers/dateFormatter";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import PropTypes from "prop-types";

const ShortCodesDataTable = (props) => {
    const [show, setShow] = useState(false);

    const {
        deleteShortCode,
        shortCode,
        handleClick,
        isItemSelected,
        key,
        labelId,
        headSelected,
    } = props;

    const openConfirmModalHandler = () => {
        setShow(true);
    };

    const deleteShortCodeHandler = () => {
        deleteShortCode(shortCode.id);
    };
    return (
        <React.Fragment>
            <TableRow
                hover
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={key}
                selected={isItemSelected}
            >
                <TableCell padding="checkbox">
                    <Checkbox
                        onClick={(event) => handleClick(event, shortCode.id)}
                        role="checkbox"
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                    />
                </TableCell>
                {headSelected.id && <TableCell>{shortCode.id}</TableCell>}
                {headSelected.mnoid && <TableCell>{shortCode.mnoid}</TableCell>}
                {headSelected.orgid && <TableCell>{shortCode.orgid}</TableCell>}
                {headSelected.price && <TableCell>{shortCode.price}</TableCell>}
                {headSelected.shortcode && <TableCell>{shortCode.shortcode}</TableCell>}
                {headSelected.Actions && (
                    <TableCell>
                        <div className="TableIcons">
                            <Link to={`/editshortCode/${shortCode.id}`}>
                                <FaRegEdit size={24} color="#00AEEF" />
                            </Link>
                            <RiDeleteBinLine
                                cursor="pointer"
                                onClick={openConfirmModalHandler}
                                color="#ED1650"
                                size={24}
                            />
                        </div>
                    </TableCell>
                )}
            </TableRow>
            {show && (
                <ConfirmModal
                    handleClose={() => setShow(false)}
                    handleShow={() => setShow(true)}
                    message={`Are you sure you want to delete ${shortCode.id} from Short Codes?`}
                    setShow={setShow}
                    show={show}
                >
                    <button className="btn btn-danger" onClick={deleteShortCodeHandler}>
                        Delete
                    </button>
                </ConfirmModal>
            )}
        </React.Fragment>
    );
};

ShortCodesDataTable.propTypes = {
    deleteShortCode: PropTypes.func,
    shortCode: PropTypes.object,
    handleClick: PropTypes.func,
    isItemSelected: PropTypes.bool,
    key: PropTypes.number,
    labelId: PropTypes.string,
    headSelected: PropTypes.object,
};

export default ShortCodesDataTable;
