import { Grid, IconButton } from "@material-ui/core";
import minus from "assets/images/minuscircle.svg";
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import Joi from "joi";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { BiPlus } from "react-icons/bi";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import danger from "../../../assets/images/danger.svg";
import "../../../assets/styles/base.scss";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import uploadOnS3 from "../../../helpers/uploadOnS3";
import toaster from "../../../modules/toaster";
import {
  createProject,
  editBankPayment,
  editCCPayment,
  editProject,
  editSMSPayment,
} from "../../../reducers/Projects";

const schema = Joi.object({
  nameEN: Joi.string().max(60),
  nameAR: Joi.string().max(60),
  infoAR: Joi.string().max(1000),
  infoEN: Joi.string().max(1000),
  mediaURL: Joi.string().max(150),
});

class Project extends Component {
  state = {
    smsRows: [0],
    showBank: false,
    showSMS: false,
    isEdit: false,
    showCreditCard: false,
    isInfoRequired: false,

    description: EditorState.createEmpty(),
    isDescriptionRequired: false,
    values: {
      actid: 0,
      actIntId: "",
      orgIntId: "",
      orgNameAR: "",
      infoAR: EditorState.createEmpty(),
      infoEN: EditorState.createEmpty(),
      imageUpload: "",
      nameAR: "",
      nameEN: "",
      org_id: 0,
      sms_payment: [
        {
          keyword_ar: "",
          keyword_en: "",
          mno: 0,
          price: 0,
          short_code: 0,
          id: 0,
        },
      ],
      bank_payment: [
        {
          IBAN: "",
          accNum: "",
          accNameAR: "",
          accNameEN: "",
          countryAR: "الامارات",
          countryEN: "UAE",
          noteAR: "",
          noteEN: "",
          nameAR: "",
          nameEN: "",
        },
      ],
      cc_payment: [
        {
          cc_url: "",
        },
      ],
    },
  };

  componentDidMount() {
    let params = this.props.location.search;
    if (params.includes("edit")) {
      this.setState({ isEdit: true });
      this.setState({ showSMS: true });
      if (this.props.currentProject.bank_payment.length > 0) {
        this.setState({ showBank: true });
      }
      if (this.props.currentProject.cc_payment.length > 0) {
        this.setState({ showCreditCard: true });
      }
      this.setState((prevState) => {
        return {
          values: {
            ...prevState.values,
            actid: this.props.currentProject.actid,
            nameEN: this.props.currentProject.nameEN,
            nameAR: this.props.currentProject.nameAR,

            orgid: this.props.currentProject.orgid,
            infoAR: EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(
                  this.props.currentProject.infoAR
                    ? this.props.currentProject.infoAR
                    : `<p>No description</p>`
                )
              )
            ),

            infoEN: EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(
                  this.props.currentProject.infoEN
                    ? this.props.currentProject.infoEN
                    : `<p>No description</p>`
                )
              )
            ),
            imageUpload: this.props.currentProject.mediaURL,

            sms_payment: this.props.currentProject.sms_payment.map(
              (sms, index) => {
                return {
                  mno: sms.mno,
                  price: sms.price,
                  short_code: sms.short_code,
                  keyword_en: sms.keyword_en,
                  keyword_ar: sms.keyword_ar,
                  id: sms.id,
                };
              }
            ),
            bank_payment: this.props.currentProject.bank_payment.map(
              (bank, index) => {
                return {
                  IBAN: bank.IBAN,
                  accNum: bank.accNum,
                  accNameAR: bank.accNameAR,
                  accNameEN: bank.accNameEN,
                  nameAR: bank.nameAR,
                  nameEN: bank.nameEN,
                  noteAR: bank.noteAR,
                  noteEN: bank.noteEN,
                  countryAR: "الامارات",
                  countryEN: "UAE",
                  BankId: bank?.Bankid,
                };
              }
            ),
            cc_payment: this.props.currentProject.cc_payment.map(
              (cc, index) => {
                return {
                  cc_url: cc.cc_url,
                  ccid: cc.ccid,
                };
              }
            ),
          },
        };
      });
    } else if (params.includes("duplicate")) {
      // this.handleDuplicateOrEditSMSFields();
      this.setState({ showSMS: true });
      if (this.props.currentProject?.bank_payment?.length > 0) {
        this.setState({ showBank: true });
      }
      if (this.props.currentProject?.cc_payment?.length > 0) {
        this.setState({ showCreditCard: true });
      }
      this.setState((prevState) => {
        return {
          values: {
            ...prevState.values,
            actid: this.props.currentProject.actid,
            nameEN: this.props.currentProject.nameEN,
            nameAR: this.props.currentProject.nameAR,

            orgid: this.props.currentProject.orgid,
            infoAR: EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(
                  this.props.currentProject.infoAR
                    ? this.props.currentProject.infoAR
                    : `<p>No description</p>`
                )
              )
            ),

            infoEN: EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(
                  this.props.currentProject.infoEN
                    ? this.props.currentProject.infoEN
                    : `<p>No description</p>`
                )
              )
            ),

            imageUpload: this.props.currentProject.mediaURL,

            sms_payment: this.props.currentProject.sms_payment.map(
              (sms, index) => {
                return {
                  mno: sms.mno,
                  price: sms.price,
                  short_code: sms.short_code,
                  keyword_en: sms.keyword_en,
                  keyword_ar: sms.keyword_ar,
                  id: sms.id,
                };
              }
            ),
            bank_payment: this.props.currentProject.bank_payment.map(
              (bank, index) => {
                return {
                  IBAN: bank.IBAN,
                  accNum: bank.accNum,
                  accNameAR: bank.accNameAR,
                  accNameEN: bank.accNameEN,
                  nameAR: bank.nameAR,
                  nameEN: bank.nameEN,
                  noteAR: bank.noteAR,
                  noteEN: bank.noteEN,
                  countryAR: "الامارات",
                  countryEN: "AUE",
                  // id: bank.BankId,
                };
              }
            ),
            cc_payment: this.props.currentProject.cc_payment.map(
              (cc, index) => {
                return {
                  cc_url: cc.cc_url,
                };
              }
            ),
          },
        };
      });
    }

    // this.setState({
    //   showBank:
    //     this.props.currentProject &&
    //     this.props.currentProject.bank_payment &&
    //     this.props.currentProject.bank_payment.length > 0,
    // });

    // this.setState({
    //   showCreditCard:
    //     this.props.currentProject &&
    //     this.props.currentProject.cc_payment &&
    //     this.props.currentProject.cc_payment.length > 0,
    // });

    // this.setState({
    //   showSMS:
    //     this.props.currentProject &&
    //     this.props.currentProject.sms_payment &&
    //     this.props.currentProject.sms_payment.length > 0,
    // });
  }
  addSmsRowCheck = () => {
    let params = this.props.location.search;
    if (
      !params.includes("duplicate") &&
      !this.props.currentProject?.sms_payment?.length > 0
    ) {
      let rows = this.state.smsRows;
      rows.push(rows[this.state.smsRows.length - 1] + 1);
      this.setState({
        smsRows: rows,
      });
      this.setState((prevState) => {
        const newValues = [
          ...(Array.isArray(prevState.values.sms_payment) &&
            prevState.values.sms_payment),
          {
            keyword_ar: "",
            keyword_en: "",
            mno: 0,
            price: 0,
            short_code: 0,
            id: Date.now() % 100000,
          },
        ];
        return {
          values: {
            ...prevState.values,
            sms_payment: newValues,
          },
        };
      });
    }
  };
  addSmsRow = () => {
    //2
    let rows = this.state.smsRows;
    rows.push(rows[this.state.smsRows.length - 1] + 1);
    this.setState({
      smsRows: rows,
    });
    this.setState((prevState) => {
      const newValues = [
        ...(Array.isArray(prevState.values.sms_payment) &&
          prevState.values.sms_payment),
        {
          keyword_ar: "",
          keyword_en: "",
          mno: 0,
          price: 0,
          short_code: 0,
          id: Date.now() % 100000,
        },
      ];
      return {
        values: {
          ...prevState.values,
          sms_payment: newValues,
        },
      };
    });
  };
  removeSmsRow = (rowNum) => {
    let rows = this.state.smsRows.filter((num) => num != rowNum);
    this.setState({
      smsRows: rows,
    });
    this.setState((prevState) => {
      const newValues = prevState.values.sms_payment.filter(
        (key) => key.id !== rowNum
      );

      return {
        values: {
          ...prevState.values,
          sms_payment: newValues,
        },
      };
    });
  };
  handleDuplicateOrEditSMSFields = () => {
    if (this.props.currentProject && this.props.currentProject.sms_payment)
      for (let i = 1; i < this.props.currentProject.sms_payment.length; i++)
        this.addSmsRow();
  };
  // changes
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => {
      return {
        values: {
          ...prevState.values,
          [name]: value,
        },
      };
    });
  };
  // change sms
  mnoChange = (keyId, newValue) => {
    const { value } = newValue?.target;
    this.setState((prevState) => {
      const newValues = prevState.values.sms_payment.map((key) => {
        if (key.id === keyId) {
          return {
            ...key,
            mno: value,
          };
        }
        return key;
      });
      return {
        values: {
          ...prevState.values,
          sms_payment: newValues,
        },
      };
    });
  };
  shortCodeChange = (keyId, newValue) => {
    this.setState((prevState) => {
      const newValues = prevState.values.sms_payment.map((key) => {
        if (key.id === keyId) {
          return {
            ...key,
            short_code: newValue,
          };
        }
        return key;
      });

      return {
        values: {
          ...prevState.values,
          sms_payment: newValues,
        },
      };
    });
  };
  keywordChange = (keyId, newValue) => {
    this.setState((prevState) => {
      const newValues = prevState.values.sms_payment.map((key) => {
        if (key.id === keyId) {
          return {
            ...key,
            keyword_ar: newValue,
          };
        }
        return key;
      });

      return {
        values: {
          ...prevState.values,
          sms_payment: newValues,
        },
      };
    });
  };
  keywordENChange = (keyId, newValue) => {
    this.setState((prevState) => {
      const newValues = prevState.values.sms_payment.map((key) => {
        if (key.id === keyId) {
          return {
            ...key,
            keyword_en: newValue,
          };
        }
        return key;
      });

      return {
        values: {
          ...prevState.values,
          sms_payment: newValues,
        },
      };
    });
  };
  priceChange = (keyId, newValue) => {
    this.setState((prevState) => {
      const newValues = prevState.values.sms_payment.map((key) => {
        if (key.id === keyId) {
          return {
            ...key,
            price: newValue,
          };
        }
        return key;
      });

      return {
        values: {
          ...prevState.values,
          sms_payment: newValues,
        },
      };
    });
  };
  // bankChanges
  bankChanges = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => {
      const newValues = prevState.values.bank_payment.map((key) => {
        return {
          ...key,
          [name]: value,
        };
      });

      return {
        values: {
          ...prevState.values,
          bank_payment: newValues,
        },
      };
    });
  };
  // ccChanges
  ccChanges = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => {
      const newValues = prevState.values.cc_payment.map((key) => {
        return {
          ...key,
          [name]: value,
        };
      });

      return {
        values: {
          ...prevState.values,
          cc_payment: newValues,
        },
      };
    });
  };
  // submit
  storeImageHandler = (e) => {
    e.preventDefault();
    const file = e.target.files[0];

    this.setState((prevState) => {
      return {
        values: {
          ...prevState.values,
          imageUpload: file,
        },
      };
    });
  };

  validateProject = (values) => {
    return (
      this.props.projects.filter((project) => {
        return (
          project.orgid == this.props.selectedOrg &&
          project.actid == values.actid &&
          project.isactive == 1
        );
      }).length == 0
    );
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    let values = this.state.values;
    let params = this.props.location.search;

    let imgURL = "";

    if (values.imageUpload.name) {
      const data = await uploadOnS3(
        values.imageUpload,
        values.imageUpload.name.split(".").slice(0, -1).join("."),
        "projects"
      );

      imgURL = data.Location;
    }
    if (!values.imageUpload.name && values.imageUpload) {
      imgURL = values.imageUpload;
    }
    if (
      !params.includes("edit") &&
      (!this.props.selectedOrg || this.props.selectedOrg == "")
    )
      return toaster.error(
        "You must select an organization before creating a Project."
      );
    if (!params.includes("edit") && !this.validateProject(values)) {
      return toaster.error(
        "There is an existing project with the same type as this new one."
      );
    }

    values.act_id = Number(values.actid);
    if (!params.includes("edit"))
      values.org_id = Number(this.props.selectedOrg);
    else values.org_id = values.orgid;

    delete values.addCreditCardField;
    delete values.addBankField;
    delete values.addSMSField;
    delete values.actid;
    delete values.orgid;

    if (values.sms_payment && values.sms_payment.length > 0) {
      values.sms_payment = values.sms_payment.filter((sms) => !!sms);
      for (let sms of values.sms_payment) {
        sms.mno = Number(sms.mno);
        sms.price = Number(sms.price);
        sms.short_code = Number(sms.short_code);
      }
    }

    // values.actIntId
    // values.orgIntId

    let currentActivity = this.props.activities.filter(
      (act) => act.id == values.act_id
    );

    if (currentActivity.length > 0) values.actIntId = currentActivity[0].intID;

    let currentOrg = this.props.orgs.filter((org) => org.id == values.org_id);

    if (currentOrg.length > 0) {
      values.orgIntId = currentOrg[0].intID;
      values.orgNameAR = currentOrg[0].nameAR;
    }
    if (!params.includes("edit")) {
      if (values.cc_payment)
        for (let cc of values.cc_payment) if (cc.ccid) delete cc.ccid;
    }

    delete values.isactive;

    if (!params.includes("edit")) {
      if (values.sms_payment && values.sms_payment.length > 0)
        for (let sms of values.sms_payment) delete sms.id;

      if (values.bank_payment && values.bank_payment.length > 0)
        for (let bank of values.bank_payment) delete bank.Bankid;

      delete values.id;

      if (imgURL) {
        delete values.imageUpload;
        values = { ...values, mediaURL: imgURL };
      }
      values = {
        ...values,
        infoAR: stateToHTML(values.infoAR.getCurrentContent()),
        infoEN: stateToHTML(values.infoEN.getCurrentContent()),
      };

      //   if(!this.state.showSMS){
      //     values = {
      //       ...values,
      //  sms_payment:[]
      //     };
      //   }
      if (!params.includes("edit")) {
        if (!this.state.showBank) {
          values = {
            ...values,
            bank_payment: [],
          };
        }
        if (!this.state.showCreditCard) {
          values = {
            ...values,
            cc_payment: [],
          };
        }
      }

      // const { error } = schema.validate({
      //   nameEN: values.nameEN,
      //   nameAR: values.nameAR,
      //   infoAR: values.infoAR,
      //   infoEN: values.infoEN,
      //   mediaURL: values.mediaURL,
      // });

      // if (error) {
      //   return toaster.error(error.details[0].message);
      // }

      return this.props
        .createProject(values)
        .then(() => {
          toaster.success("project created successully");
          this.props.history.push("/projects/list");
        })
        .catch((err) => {
          console.error("error creating project: ", err);
        });
    }
    // sms

    let removedSMSs = [];

    let addedSMSs = [];

    let updatedSMSs = [];

    let updatedInfo = null;

    const oldProject = this.props.duplicateInit;

    if (!this.compareProjectInfo(oldProject, values)) {
      values = {
        ...values,
        infoAR: stateToHTML(values.infoAR.getCurrentContent()),
        infoEN: stateToHTML(values.infoEN.getCurrentContent()),
      };

      updatedInfo = {
        infoAR: values.infoAR,
        infoEN: values.infoEN,
        nameEN: values.nameEN.toString(),
        nameAR: values.nameAR.toString(),
        act_id: values.act_id,
        mediaURL: values.mediaURL,
      };
      if (imgURL) {
        updatedInfo = { ...updatedInfo, mediaURL: imgURL };
      }
    }
    removedSMSs = this.getRemovedSMSs(oldProject, values);
    addedSMSs = this.getAddedSMSs(oldProject, values);
    updatedSMSs = this.getUpdatedSMSs(oldProject, values);

    let smsPayload = null;

    if (
      removedSMSs.length > 0 ||
      addedSMSs.length > 0 ||
      updatedSMSs.length > 0
    ) {
      smsPayload = {};

      if (removedSMSs.length > 0) {
        smsPayload.sms_payment_to_remove = removedSMSs;
      }

      if (addedSMSs.length > 0) {
        smsPayload.sms_payment_to_add = addedSMSs;
      }

      if (updatedSMSs.length > 0) {
        smsPayload.sms_payment_to_update = updatedSMSs;
      }

      smsPayload.id = oldProject.id;
    }
    if (smsPayload?.sms_payment_to_add) {
      for (let sms of smsPayload.sms_payment_to_add) {
        delete sms.id;
      }
    }
    if (smsPayload) await this.props.editSMSPayment(smsPayload);
    // bank
    let updateBank = [];
    let addBank = [];

    addBank = this.getAddedBank(oldProject, values);
    updateBank = this.getUpdateBank(oldProject, values);
    let bankPayload = null;
    if (addBank.length > 0 || updateBank.length > 0) {
      bankPayload = {};
      if (addBank.length > 0) {
        bankPayload.bank_payment_to_add = addBank;
      }
      if (updateBank.length > 0) {
        bankPayload.bank_payment_to_update = updateBank;
      }
      bankPayload.id = oldProject.id;
    }

    if (bankPayload) await this.props.editBankPayment(bankPayload);
    // cc payment
    let addCC = [];
    let updateCC = [];
    let CCPayload = null;
    addCC = this.getAddedCC(oldProject, values);
    updateCC = this.getUpdateCC(oldProject, values);

    if (addCC.length > 0 || updateCC.length > 0) {
      CCPayload = {};

      if (addCC.length > 0) {
        CCPayload.cc_payment_to_add = addCC;
      }
      if (updateCC.length > 0) {
        CCPayload.cc_payment_to_update = updateCC;
      }
      CCPayload.id = oldProject.id;
    }

    if (CCPayload) await this.props.editCCPayment(CCPayload);

    if (updatedInfo) {
      // const { error } = schema.validate({
      //   nameEN: updatedInfo.nameEN,
      //   nameAR: updatedInfo.nameAR,
      //   infoAR: updatedInfo.infoAR,
      //   infoEN: updatedInfo.infoEN,
      //   mediaURL: updatedInfo.mediaURL,
      // });

      // if (error) {
      //   return toaster.error(error.details[0].message);
      // }

      await this.props.editProject({
        project_data_to_update: updatedInfo,
        id: oldProject.id,
      });
      toaster.success("project updated successully");

      this.props.history.push("/projects/list");
    }
  };

  compareProjectInfo = (oldProject, newProject) => {
    return (
      oldProject.infoAR == newProject.infoAR &&
      oldProject.infoEN == newProject.infoEN &&
      oldProject.nameEN == newProject.nameEN &&
      oldProject.nameAR == newProject.nameAR &&
      oldProject.actid == newProject.act_id &&
      oldProject.mediaURL == newProject.mediaURL &&
      oldProject.imageUpload == newProject.imageUpload
    );
  };

  getRemovedSMSs = (oldProject, newProject) => {
    let smsList = [];
    for (let oldSms of oldProject.sms_payment) {
      let found = false;
      for (let sms of newProject.sms_payment)
        if (oldSms.id == sms.id) found = true;
      if (!found) smsList.push(oldSms.id);
    }

    return smsList;
  };

  getAddedSMSs = (oldProject, newProject) => {
    let smsList = [];
    for (let sms of newProject.sms_payment) {
      let found = false;
      for (let oldSms of oldProject.sms_payment)
        if (oldSms.id == sms.id) found = true;
      if (!found) smsList.push(sms);
    }
    return smsList;
  };

  getUpdatedSMSs = (oldProject, newProject) => {
    let smsList = [];
    for (let sms of newProject.sms_payment) {
      let updated = false;
      for (let oldSms of oldProject.sms_payment)
        if (oldSms.id == sms.id && !this.compareSMSs(oldSms, sms))
          updated = true;
      if (updated) smsList.push(sms);
    }
    return smsList;
  };
  getAddedBank = (oldProject, newProject) => {
    let BankList = [];
    for (let bank of newProject.bank_payment) {
      let found = false;
      for (let oldBank of oldProject.bank_payment)
        if (oldBank?.Bankid) found = true;

      if (!found) BankList.push(bank);
    }
    return BankList;
  };

  getUpdateBank = (oldProject, newProject) => {
    let BankList = [];
    for (let bank of newProject.bank_payment) {
      let updated = false;
      for (let oldBank of oldProject.bank_payment)
        if (!this.compareBank(oldBank, bank)) updated = true;
      if (updated) BankList.push(bank);
    }
    return BankList;
  };
  getAddedCC = (oldProject, newProject) => {
    let CCList = [];
    for (let CC of newProject.cc_payment) {
      let found = false;
      for (let oldCC of oldProject.cc_payment) if (oldCC?.ccid) found = true;

      if (!found) CCList.push(CC);
    }
    return CCList;
  };

  getUpdateCC = (oldProject, newProject) => {
    let CCList = [];
    for (let CC of newProject.cc_payment) {
      let updated = false;
      for (let oldCC of oldProject.cc_payment)
        if (!this.compareCC(oldCC, CC)) updated = true;
      if (updated) CCList.push(CC);
    }
    return CCList;
  };
  compareSMSs = (oldSms, newSms) => {
    return (
      newSms.keyword_ar == oldSms.keyword_ar &&
      newSms.mno == oldSms.mno &&
      newSms.keyword_en == oldSms.keyword_en &&
      newSms.price == oldSms.price &&
      newSms.short_code == oldSms.short_code
    );
  };
  compareBank = (oldBank, newBank) => {
    return (
      newBank.IBAN == oldBank.IBAN &&
      newBank.BankId == oldBank.BankId &&
      newBank.accNum == oldBank.accNum &&
      newBank.accNameAR == oldBank.accNameAR &&
      newBank.accNameEN == oldBank.accNameEN &&
      newBank.noteAR == oldBank.noteAR &&
      newBank.noteEN == oldBank.noteEN &&
      newBank.nameAR == oldBank.nameAR &&
      newBank.nameEN == oldBank.nameEN
    );
  };

  compareCC = (oldCC, newCC) => {
    return newCC.cc_url == oldCC.cc_url;
  };
  render() {
    const { actid, nameAR, nameEN, imageUpload } = this.state.values;
    if (this.props.isPending) {
      return <LoadingSpinner />;
    }

    return (
      <div dir="rtl">
        <div className="header">
          {!this.state.isEdit && <h4>مشروع جديد</h4>}
          {this.state.isEdit && <h4>تعديل مشروع</h4>}
        </div>

        <form
          className="card"
          style={{ padding: "12px 24px" }}
          onSubmit={this.handleSubmit}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid item md={6} className="form-group">
                <label className=" control-label">الفئة</label>

                <select
                  className="newProjectSelect"
                  name="actid"
                  required
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  value={actid}
                >
                  <option value=""></option>
                  {this.props.activities?.map((activity) => (
                    <option key={activity.id} value={activity.id}>
                      {activity.nameAR}
                    </option>
                  ))}
                </select>
              </Grid>
            </Grid>
            <Grid item md={6} className="form-group">
              <label className=" control-label">اسم المشروع (عربى)</label>

              <input
                type="text"
                name="nameAR"
                value={nameAR}
                required
                onChange={(e) => {
                  this.handleChange(e);
                }}
              />
            </Grid>
            <Grid item md={6} sm={12} className="form-group">
              <label className="col-sm-12 control-label">
                اسم المشروع (EN)
              </label>
              <input
                type="text"
                name="nameEN"
                value={nameEN}
                required
                onChange={(e) => {
                  this.handleChange(e);
                }}
              />
            </Grid>
            <Grid item sm={12} className="form-group">
              <label className=" control-label">(عربى)وصف المشروع</label>

              <Editor
                className="newProjectTextArea"
                editorState={this.state.values.infoAR}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(e) => {
                  this.setState((prevState) => {
                    return {
                      values: {
                        ...prevState.values,
                        infoAR: e,
                      },
                    };
                  });
                }}
                onFocus={() => this.setState({ isInfoRequired: false })}
                onBlur={() =>
                  this.state.values.infoAR
                    .getCurrentContent()
                    .getPlainText()
                    .trim().length <= 0 &&
                  this.setState({ isInfoRequired: true })
                }
              />
            </Grid>
            <Grid item sm={12} className="form-group">
              <label className=" control-label">(EN)وصف المشروع</label>

              <Editor
                className="newProjectTextArea"
                editorState={this.state.values.infoEN}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(e) => {
                  this.setState((prevState) => {
                    return {
                      values: {
                        ...prevState.values,
                        infoEN: e,
                      },
                    };
                  });
                }}
                onFocus={() => this.setState({ isInfoRequired: false })}
                onBlur={() =>
                  this.state.values.infoEN
                    .getCurrentContent()
                    .getPlainText()
                    .trim().length <= 0 &&
                  this.setState({ isInfoRequired: true })
                }
              />
            </Grid>
            <Grid item lg={6} sm={12} className="form-group media">
              <label className="control-label">MediaURL</label>
              <div className=" mediaLabel">
                <input
                  className="newProjectTextArea"
                  // type="text"
                  name="mediaURL"
                  readOnly
                  value={imageUpload?.name || imageUpload}
                />

                <IconButton
                  aria-label="upload picture"
                  component="Label"
                  className="upload"
                >
                  <input
                    name="imageUpload"
                    accept="image/*"
                    type="file"
                    className="input-upload"
                    onChange={this.storeImageHandler}
                  />
                  upload
                </IconButton>
              </div>
            </Grid>
            <Grid item sm={12}>
              <div className="form-group create-project-custom-table">
                {/* {!this.state.isEdit && ( */}
                <div className="row short-msgs-row add-bank-label">
                  <label className="control-label"> اضافة رسالة نصية</label>

                  <input
                    name="addSMSField"
                    type="checkbox"
                    // disabled={this.state.isEdit}
                    checked={this.state.showSMS}
                    value={this.state.showSMS}
                    onChange={() => {
                      this.setState({ showSMS: !this.state.showSMS });
                      this.addSmsRowCheck();
                    }}
                    data-toggle="checkbox"
                    style={{
                      width: "17px",
                      marginLeft: "11px",
                      height: "17px",
                    }}
                  />
                </div>
                {/* )} */}

                {this.state.showSMS && (
                  <>
                    <div className="row short-msgs-row msgBG">
                      <label className="control-label">
                        <img src={danger} alt="danger" />
                        التبرع بالرسائل القصيرة
                      </label>
                      <p className="sms-note">
                        Note that to remove an sms field you have to remove from
                        the end of the list till you reach the target field
                      </p>
                    </div>
                    {this.state.values.sms_payment?.map((rowNum, index) => {
                      const {
                        mno,
                        price,
                        short_code,
                        keyword_en,
                        keyword_ar,
                        id,
                      } = this.state.values.sms_payment[index];
                      return (
                        <Grid
                          container
                          spacing={3}
                          sm={12}
                          key={id}
                          style={{ position: "relative" }}
                        >
                          <Grid item md={3} sm={6} className="form-group">
                            <label className="control-label">
                              نوع شبكة المحمول
                            </label>

                            <select
                              // value={sms_payment.mno}
                              className="newProjectSelect"
                              name={`sms_payment[${rowNum}].` + "mno"}
                              required
                              value={mno}
                              onChange={(newValue) => {
                                this.mnoChange(id, newValue);
                              }}
                            >
                              <option></option>
                              {this.props.mnos?.map((mno) => (
                                <option key={mno.id} value={mno.id}>
                                  {mno.nameEN}
                                </option>
                              ))}
                            </select>
                          </Grid>
                          <Grid item md={3} sm={6} className="form-group">
                            <label className="control-label">رقم التبرع</label>

                            <input
                              type="number"
                              name={`sms_payment[${rowNum}].` + "short_code"}
                              required
                              value={short_code}
                              placeholder="رقم التبرع"
                              onChange={(e) => {
                                this.shortCodeChange(id, e.target.value);
                              }}
                            />
                          </Grid>
                          <Grid item md={3} sm={6} className="form-group">
                            <label className="control-label">نص الرسالة</label>

                            <input
                              type="text"
                              name={`sms_payment[${rowNum}].` + "keyword_ar"}
                              required
                              placeholder="نص الرسالة"
                              value={keyword_ar}
                              onChange={(e) => {
                                this.keywordChange(id, e.target.value);
                              }}
                            />
                          </Grid>
                          <Grid item md={2} sm={3} className="form-group">
                            <label className="control-label">Keyword </label>

                            <input
                              type="text"
                              name={`sms_payment[${rowNum}].` + "keyword_en"}
                              required
                              value={keyword_en}
                              placeholder="Keyword"
                              onChange={(e) => {
                                this.keywordENChange(id, e.target.value);
                              }}
                            />
                          </Grid>
                          <Grid item md={1} sm={3} className="form-group">
                            <label className="control-label">السعر</label>

                            <input
                              type="number"
                              name={`sms_payment[${rowNum}].` + "price"}
                              required
                              value={price}
                              placeholder="0"
                              onChange={(e) => {
                                this.priceChange(id, e.target.value);
                              }}
                            />

                            {index != 0 && (
                              <img
                                style={{
                                  position: "absolute",
                                  left: "-20px",
                                  top: "45%",
                                }}
                                src={minus}
                                alt="minus"
                                onClick={() => {
                                  this.removeSmsRow(id);
                                }}
                              />
                            )}
                          </Grid>
                        </Grid>
                      );
                    })}
                    <button
                      type="button"
                      onClick={this.addSmsRow}
                      className="add-short-msgs-btn"
                    >
                      <BiPlus />
                      اضافة رسالة نصية
                    </button>
                  </>
                )}
              </div>
            </Grid>
            <Grid item sm={12}>
              <div className="form-group create-project-custom-table">
                <div className="row short-msgs-row add-bank-label">
                  <label className="control-label"> اضافة بنك</label>

                  <input
                    name="addBankField"
                    type="checkbox"
                    // disabled={this.state.isEdit}
                    checked={this.state.showBank}
                    onChange={this.toggleBank}
                    data-toggle="checkbox"
                    style={{
                      width: "17px",
                      marginLeft: "11px",
                      height: "17px",
                    }}
                  />
                </div>
                {this.state.showBank && (
                  <Grid container spacing={4}>
                    <Grid item md={3} sm={12} className="form-group">
                      <label className="control-label">اسم البنك</label>

                      <input
                        type="text"
                        name={"nameAR"}
                        value={this.state.values?.bank_payment[0]?.nameAR}
                        // disabled={this.state.isEdit}
                        required
                        onChange={(e) => {
                          this.bankChanges(e);
                        }}
                      />
                    </Grid>

                    <Grid item md={3} sm={12} className="form-group">
                      <label className="control-label">Bank Name </label>

                      <input
                        type="text"
                        name={"nameEN"}
                        value={this.state.values?.bank_payment[0]?.nameEN}
                        // disabled={this.state.isEdit}
                        required
                        onChange={(e) => {
                          this.bankChanges(e);
                        }}
                      />
                    </Grid>
                    <Grid item md={3} sm={12} className="form-group">
                      <label className="control-label"> اسم الحساب AR</label>

                      <input
                        type="text"
                        name={"accNameAR"}
                        value={this.state.values?.bank_payment[0]?.accNameAR}
                        // disabled={this.state.isEdit}
                        required
                        onChange={(e) => {
                          this.bankChanges(e);
                        }}
                      />
                    </Grid>
                    <Grid item md={3} sm={12} className="form-group">
                      <label className="control-label">اسم الحساب EN</label>

                      <input
                        type="text"
                        name={"accNameEN"}
                        value={this.state.values?.bank_payment[0]?.accNameEN}
                        // disabled={this.state.isEdit}
                        // required
                        onChange={(e) => {
                          this.bankChanges(e);
                        }}
                      />
                    </Grid>
                    <Grid item md={3} sm={12} className="form-group">
                      <label className="control-label">رقم الحساب</label>

                      <input
                        type="text"
                        name={"accNum"}
                        value={this.state.values?.bank_payment[0]?.accNum}
                        // disabled={this.state.isEdit}
                        required
                        onChange={(e) => {
                          this.bankChanges(e);
                        }}
                      />
                    </Grid>
                    <Grid item md={3} sm={12} className="form-group">
                      <label className="control-label">IBAN</label>

                      <input
                        type="text"
                        name={"IBAN"}
                        value={this.state.values?.bank_payment[0]?.IBAN}
                        // disabled={this.state.isEdit}
                        required
                        onChange={(e) => {
                          this.bankChanges(e);
                        }}
                      />
                    </Grid>
                    <Grid item md={3} sm={12} className="form-group">
                      <label className="control-label">ARملاحظات</label>

                      <input
                        type="text"
                        name={"noteAR"}
                        value={this.state.values?.bank_payment[0]?.noteAR}
                        // disabled={this.state.isEdit}
                        required
                        onChange={(e) => {
                          this.bankChanges(e);
                        }}
                      />
                    </Grid>
                    <Grid item md={3} sm={12} className="form-group">
                      <label className="control-label">Enملاحظات</label>

                      <input
                        type="text"
                        name={"noteEN"}
                        value={this.state.values?.bank_payment[0]?.noteEN}
                        // disabled={this.state.isEdit}
                        required
                        onChange={(e) => {
                          this.bankChanges(e);
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
              </div>
            </Grid>
            <Grid item sm={12}>
              <div className="form-group create-project-custom-table">
                <div className="row short-msgs-row add-bank-label">
                  <label className="control-label">اضافة بطاقة إئتمانية</label>

                  <input
                    name="addCreditCardField"
                    type="checkbox"
                    // disabled={this.state.isEdit}
                    checked={this.state.showCreditCard}
                    onChange={this.toggleCreditCard}
                    data-toggle="checkbox"
                    style={{
                      width: "17px",
                      marginLeft: "11px",
                      height: "17px",
                    }}
                  />
                </div>

                {this.state.showCreditCard && (
                  <Grid item md={6} sm={12}>
                    <div className="row short-msgs-header-row form-group">
                      <label className="control-label">رابط موقع التبرع </label>
                    </div>

                    <input
                      type="text"
                      value={this.state.values?.cc_payment[0]?.cc_url}
                      // disabled={this.state.isEdit}
                      name={"cc_url"}
                      onChange={(e) => {
                        this.ccChanges(e);
                      }}
                    />
                  </Grid>
                )}
              </div>
            </Grid>
          </Grid>

          <div className="submit">
            <button type="submit" className="create-edit-form-btn">
              Confirm {"  "}
            </button>
          </div>
        </form>
      </div>
    );
  }

  toggleBank = () => {
    this.setState({ showBank: !this.state.showBank });
    if (this.state.values.bank_payment.length === 0) {
      this.setState((prevState) => {
        const newValues = [
          ...(Array.isArray(prevState.values.bank_payment) &&
            prevState.values.bank_payment),
          {
            IBAN: "",
            accNum: "",
            accNameAR: "",
            accNameEN: "",
            countryAR: "الامارات",
            countryEN: "UAE",
            noteAR: "",
            noteEN: "",
            nameAR: "",
            nameEN: "",
            // BankId:this.props?.currentProject?.bank_payment[0].Bankid
          },
        ];
        return {
          values: {
            ...prevState.values,
            bank_payment: newValues,
          },
        };
      });
    }
  };

  toggleCreditCard = () => {
    this.setState({ showCreditCard: !this.state.showCreditCard });
    if (this.state.values.cc_payment.length === 0) {
      this.setState((prevState) => {
        const newValues = [
          ...(Array.isArray(prevState.values.cc_payment) &&
            prevState.values.cc_payment),
          {
            cc_url: "",
            ccid: this.props?.currentProject?.cc_payment[0]?.ccid,
          },
        ];
        return {
          values: {
            ...prevState.values,
            cc_payment: newValues,
          },
        };
      });
    }
  };
}

export default connect(
  (state) => ({
    selectedOrg: state.ORGs.selectedOrg,
    store: state,
    isPending: state.Projects.isPending,
    initialValues: state.Projects.duplicate,
    currentProject: state.Projects.duplicate,
    activities: state.Activities.activities,
    mnos: state.MNOs.mnos,
    image: formValueSelector("Project")(state, "imageUpload"),
    projects: state.Projects.projects,
    orgs: state.ORGs.orgs,
    duplicateInit: state.Projects.duplicateInit,
  }),
  (dispatch) => ({
    createProject: (project) => dispatch(createProject(project)),
    editSMSPayment: (data) => dispatch(editSMSPayment(data)),
    editBankPayment: (data) => dispatch(editBankPayment(data)),
    editCCPayment: (data) => dispatch(editCCPayment(data)),
    editProject: (data) => dispatch(editProject(data)),
  })
)(Project);
Project.propTypes = {
  projects: PropTypes.array,
  selectedOrg: PropTypes.string,
  loadProjects: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  createProject: PropTypes.func,
  editSMSPayment: PropTypes.func,
  editProject: PropTypes.func,
  activities: PropTypes.array,
  orgs: PropTypes.array,
  duplicateInit: PropTypes.object,
};
