import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button, FormControl, ToggleButton } from "react-bootstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { connect } from "react-redux";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { Grid } from "@material-ui/core";
import {
  createNewShortCode,
  editShortCode,
  getSingleShortCode,
} from "../../reducers/ShortCodes";
import toaster from "../../modules/toaster";

const AddNewShortCode = ({
  isPending,
  singleShortCode,
  getSingleShortCode,
  createNewShortCode,
  editShortCode,
  history,
  orgs,
  mnos,
  
}) => {
  const shortCodeId =
    window.location.hash.includes("editshortCode") &&
    window.location.hash.split("/")[window.location.hash.split("/").length - 1];
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [orgid, setOrgid] = useState("");
  const [mnoid, setMnoid] = useState("");
  const [price, setPrice] = useState("");
  const [shortcode, setShortCode] = useState("");
  const [isactive, setIsactive] = useState(true);

  const submitShortCodesDataHandler = (e) => {
    e.preventDefault();

    const shortCodesData = {
      orgid,
      mnoid,
      price,
      shortcode,
      isactive,
    };

    if (mnoid === "" || mnoid === null) {
      toaster.warning('please select mnoid');
      return;
    }
    else if (orgid === "" || orgid === null) {
      toaster.warning('please select orgid');
      return;
    }
    else if (isEditingMode) {
      editShortCode(shortCodeId, shortCodesData);
    }
    else {
      createNewShortCode(shortCodesData);
    }
    history.push("/shortCodes");
  };

  useEffect(() => {
    if (shortCodeId) {
      getSingleShortCode(shortCodeId);
      setIsEditingMode(true);
      setOrgid(singleShortCode?.shortcode?.orgid);
      setMnoid(singleShortCode?.shortcode?.mnoid);
      setPrice(singleShortCode?.shortcode?.price);
      setShortCode(singleShortCode?.shortcode?.shortcode);
      setIsactive(singleShortCode?.shortcode?.isactive === 1);
    } else {
      setIsEditingMode(false);
    }
  }, [shortCodeId, singleShortCode?.shortcode?.id]);

  if (isPending) return <LoadingSpinner />;

  return (
    <div>
      <div className="header">
        <h4 className="title">
          {isEditingMode
            ? `Edit Short Code: ${singleShortCode?.shortcode?.shortcode}`
            : "Add a New Short Code"}
        </h4>
      </div>
      <form
        className="card"
        style={{ padding: "12px 24px" }}
        onSubmit={submitShortCodesDataHandler}
      >
        <Grid container spacing={3} style={{ padding: "15px 28px" }}>
          <Grid item md={6} sm={12} className="form-group">
            <label id="orgid" className="control-label">
              orgid
            </label>
            <FormControl
              as="select"
              value={orgid}
              onChange={(e) => setOrgid(parseInt(e.target.value))}
              name="orgid"
              htmlFor="orgid"
              placeholder="Select orgid"
              required
            >
              <option >Select Organization</option>
              {orgs.map(org => (
                <option key={org.id} value={org.id}> {org.id} - {org.nameEN} </option>
              ))}
            </FormControl>
          </Grid>
          <Grid item md={6} sm={12} className="form-group">
            <label id="mnoid" className="control-label">
              mnoid
            </label>
            <FormControl
              as="select"
              value={mnoid}
              onChange={(e) => setMnoid(parseInt(e.target.value))}
              name="mnoid"
              htmlFor="mnoid"
              placeholder="Enter mnoid"
              required
            >
              <option >Select MNOID</option>
              {mnos.map(org => (
                <option key={org.id} value={org.id}> {org.id} - {org.nameEN} </option>
              ))}
            </FormControl>
          </Grid>
          <Grid item md={6} sm={12} className="form-group">
            <label id="price" className="control-label">
              price
            </label>
            <FormControl
              type="number"
              value={price}
              onChange={(e) => setPrice(parseInt(e.target.value))}
              name="price"
              htmlFor="price"
              placeholder="Enter price"
              required
            />
          </Grid>
          <Grid item md={6} sm={12} className="form-group">
            <label id="shortcode" className="control-label">
              short code
            </label>
            <FormControl
              type="number"
              value={shortcode}
              onChange={(e) => setShortCode(parseInt(e.target.value))}
              name="shortcode"
              htmlFor="shortcode"
              placeholder="Enter shortcode"
              required
            />
          </Grid>
          <div
            className="form-group"
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <ToggleButton
              className="mb-2 checkboxButton"
              id="toggle-check"
              type="checkbox"
              checked={isactive}
              onChange={() => setIsactive(prevValue => !prevValue)}
            >
              <span className="" style={{ marginLeft: "5px" }}>
                {isactive ? "Active" : "Not Active"}
              </span>
            </ToggleButton>
          </div>
        </Grid>
        <div className="submit">
          <Button className="create-edit-form-btn" type="submit">
            {isEditingMode ? "Edit" : "Submit"}
          </Button>
        </div>
      </form>
    </div>
  );
};


export default connect(
  (state) => ({
    singleShortCode: state.ShortCodes.singleShortCode,
    isPending: state.ShortCodes.isPending,
    orgs: state.ORGs.orgs,
    mnos: state.MNOs.mnos,
  }),
  (dispatch) => ({
    getSingleShortCode: (id) => dispatch(getSingleShortCode(id)),
    editShortCode: (id, data) => dispatch(editShortCode(id, data)),
    createNewShortCode: (data) => dispatch(createNewShortCode(data)),
  })
)(AddNewShortCode);

AddNewShortCode.propTypes = {
  singleShortCode: PropTypes.object,
  isPending: PropTypes.bool,
  getSingleShortCode: PropTypes.func,
  editShortCode: PropTypes.func,
  createNewShortCode: PropTypes.func,
  history: PropTypes.object,
  orgs: PropTypes.array,
  mnos: PropTypes.array,
};

