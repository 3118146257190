import networkService from "../api.js";
import toaster from "../modules/toaster.js";

const defaultState = {
  cases: [],
  error: null,
  isPending: null,
  loaded: false,
  duplicate: null,
  duplicateInit: null,
  removedRows: [],
  msg: "",
};
const CASES_DUPLICATE = "CASES_DUPLICATE";
const UPDATE_CASES = "UPDATE_CASES";
const EDIT_CASE = "EDIT_CASE";
const CREATE_CASE = "CREATE_CASE";
const LOAD_CASES_LIST = "LOAD_CASES_LIST";
const CASES_PENDING = "CASES_PENDING";
const CASES_ERROR = "CASES_ERROR";
const DELETE_CASES="DELETE_CASES"

export const loadCasesList = (orgId) => (dispatch) => {
  dispatch({ type: CASES_PENDING });
  let data = {};

  if (orgId && orgId != "") data.org_id = orgId;
  networkService
    .send(`cases`, data)
    .then((res) => {
      dispatch({
        type: LOAD_CASES_LIST,
        cases: res.data.causes,
      });
    })
    .catch((error) => {
      dispatch({ type: CASES_ERROR, error });
    });
};

// export const loadCasesList = (orgId,skipVal) => async (dispatch) => {
//   dispatch({ type: CASES_PENDING });
//   let data = {};
//   if (orgId && orgId != "") data.org_id = orgId;

//   try {
//     loadCasesList();

//     let res = await axios.get(
//       `https://stgapi.ikhair.net/ikhair/causes?limit=20&skip=${skipVal}`
      
//     );

//     dispatch({   type: LOAD_CASES_LIST,
//       cases: res.data });
//   } catch (error) {
//     dispatch({ type: CASES_ERROR, error });
//   }
// };
// causesIDs
export const deleteCases = (casesID) => async (dispatch) => {
  dispatch({ type: CASES_PENDING });
  try {
    await networkService.send("deleteCases", casesID);
    dispatch({
      type: DELETE_CASES,
    });
    toaster.success('successfully deleted cases')
  } catch (error) {
    toaster.error(error)

    dispatch({ type: CASES_ERROR, error });
  }
};
export const duplicateCase = (urgentCase) => async (dispatch) => {
  dispatch({ type: CASES_PENDING });
  try {
    let res = await networkService.send("getCase", { id: urgentCase.id });
    dispatch({
      type: CASES_DUPLICATE,
      urgentCase: res.data.cause,
    });
  } catch (error) {
    dispatch({ type: CASES_ERROR, error });
  }
};

export const editCaseStatus = (data) => async (dispatch) => {
  dispatch({ type: CASES_PENDING });
  try {
    await networkService.send("caseStatus", data);
    dispatch({
      type: EDIT_CASE,
    });
  } catch (error) {
    dispatch({ type: CASES_ERROR, error });
  }
};

export const editSMSPayment = (data) => async (dispatch) => {
  dispatch({ type: CASES_PENDING });
  try {
    await networkService.send("updateSMSPayment", data);
    dispatch({
      type: EDIT_CASE,
    });
  } catch (error) {
    dispatch({ type: CASES_ERROR, error });
  }
};


// updateCCPayment
export const editCCPayment = (data) => async (dispatch) => {
  console.log(data,'data');
  dispatch({ type: CASES_PENDING });
  try {
    await networkService.send("updateCCPayment", data,);
    dispatch({
      type: EDIT_CASE,
    });
  } catch (error) {
    dispatch({ type: EDIT_CASE, error });
  }
};

export const updateCases = () => async (dispatch) => {
  dispatch({ type: CASES_PENDING });
  try {
    await networkService.send("updateCases", {}, {});
    dispatch({
      type: UPDATE_CASES,
      payload: "Cases Updated",
    });
    toaster.success('cases updated')
  } catch (error) {
    dispatch({ type: CASES_ERROR, error });
  }
};
export const updateUrgentCase = (data) => async (dispatch) => {
  dispatch({ type: CASES_PENDING });
  try {
    await networkService.send("updateUrgentCase", data);
    dispatch({
      type: EDIT_CASE,
    });
  } catch (error) {
    dispatch({ type: CASES_ERROR, error });
  }
};

export const editCase = (data) => async (dispatch) => {
  dispatch({ type: CASES_PENDING });
  try {
    await networkService.send("editCase", data);
    dispatch({
      type: EDIT_CASE,
    });
  } catch (error) {
    dispatch({ type: CASES_ERROR, error });
  }
};

export const createCase = (project) => async (dispatch) => {
  dispatch({ type: CASES_PENDING });
  try {
    await networkService.send("case", project);
    dispatch({
      type: CREATE_CASE,
    });
  } catch (error) {
    dispatch({ type: CASES_ERROR, error });
  }
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case LOAD_CASES_LIST:
      return {
        ...state,
        isPending: false,
        error: false,
        cases: action.cases,
        duplicate: null,
      };

    case CASES_PENDING:
      return {
        ...state,
        isPending: true,
        error: false,
      };

    case CASES_ERROR:
      return {
        ...state,
        isPending: false,
        error: action.error,
        cases: [],
        duplicate: null,
      };

    case CREATE_CASE:
      return {
        ...state,
        isPending: false,
        error: false,
        duplicate: null,
      };
      case DELETE_CASES:
        return {
          ...state,
          isPending: false,
          error: false,
          deletecases: null,
        };
    case EDIT_CASE:
      return {
        ...state,
        isPending: false,
        error: false,
        duplicate: null,
      };
    case UPDATE_CASES:
      return {
        ...state,
        msg: action.payload,
        isPending: false,
        error: false,
        duplicate: null,
      };

    case CASES_DUPLICATE:
      return {
        ...state,
        duplicate: {
          ...action.urgentCase,
          sms_payment: action.urgentCase.sms_payment
            ? [...action.urgentCase.sms_payment]
            : [],
        },
        duplicateInit: {
          ...action.urgentCase,
          sms_payment: action.urgentCase.sms_payment
            ? [...action.urgentCase.sms_payment]
            : [],
        },
        isPending: false,
        error: false,
      };
    default:
      return state;
  }
}
