// import { Pagination } from "@material-ui/lab";
import { Pagination } from "antd";
import "assets/styles/base.scss";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { BsPlus } from "react-icons/bs";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "../../../assets/styles/base.scss";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import NewDataTable from "../../../components/NewDataTable";
import SelectOrg from "../../../components/SelectOrg";
import toaster from "../../../modules/toaster";
import {
  deleteCases,
  duplicateCase,
  editCaseStatus,
  loadCasesList,
} from "../../../reducers/Cases";
import TableWithSwitch from "./TableWithSwitch";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";

const selecttableHead = [
  "ID",
  "Category",
  "ORG",
  "NameAR",
  "nameEN",
  "DescriptionAR",
  "DescriptionEN",
  "TargetAmount",
  "collectedAmount",
  "Deeplink",
  "MediaURL",
  "order",
  "Status",
  "Actions",
];

const rowsPerPage = 10;

const List = ({
  selectedOrg,
  cases,
  loadCases,
  history,
  deleteCases,
  isPending,
}) => {
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);

  const [show, setShow] = React.useState(false);
  const [headSelected, setHeadSelected] = React.useState({
    ID: true,
    Category: true,
    ORG: true,
    NameAR: true,
    nameEN: false,
    DescriptionAR: false,
    DescriptionEN: false,
    TargetAmount: false,
    collectedAmount: false,

    Deeplink: false,
    MediaURL: false,
    order: false,

    Status: true,
    Actions: true,
  });
  const [lastDeleted, setLastDeleted] = useState();
  const [causesIDs, setCausesIDs] = useState({
    causesIDs: selected,
  });
  function getKeyByValue(object, value) {
    return Object.keys(object).filter((key) => object[key] === value);
  }

  const TableHead = getKeyByValue(headSelected, true);

  const handleChange = (event) => {
    setHeadSelected({
      ...headSelected,
      [event.target.name]: event.target.checked,
    });
  };
  useEffect(() => {
    loadCases(selectedOrg);
  }, [selectedOrg, lastDeleted]);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    setCausesIDs({ causesIDs: newSelected });
  };

  const handlePaginationChange = (value) => {
    setPage(value);
  };
  const handleAddCase = () => {
    if (!selectedOrg || selectedOrg == "") {
      setShow(true);
    } else {
      history.push("/urgent/new");
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const sortedCases = cases?.sort(
    (a, b) => Number(b.isactive) - Number(a.isactive)
  );
  const handleDelete = () => {
    if (causesIDs.causesIDs.length === 0) {
      toaster.warning("please select some cases");
    } else {
      deleteCases(causesIDs).then(() => {
        setLastDeleted(causesIDs);
        // loadCases(selectedOrg);
        setSelected([]);
      });
    }
  };
  if (isPending) {
    return <LoadingSpinner />;
  }
  return (
    <div>
      <div className="header">
        <h4 className="title">Cases</h4>
      </div>
      <div className="card">
        {cases.length > 0 ? (
          <NewDataTable
            title={"Add A Case"}
            csvTitle={"Cases"}
            add={handleAddCase}
            data={cases}
            selected={selected}
            setSelected={setSelected}
            tableHead={TableHead}
            selecttableHead={selecttableHead}
            handleChange={handleChange}
            headSelected={headSelected}
            handleDelete={handleDelete}
            pagination={
              <Pagination
                size="small"
                total={cases.length}
                onChange={handlePaginationChange}
              />
            }
          >
            {sortedCases
              ?.slice(
                (page - 1) * rowsPerPage,
                (page - 1) * rowsPerPage + rowsPerPage
              )
              ?.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableWithSwitch
                    key={row.id}
                    data={cases}
                    case={row}
                    handleClick={handleClick}
                    isItemSelected={isItemSelected}
                    labelId={labelId}
                    selectedOrg={selectedOrg}
                    headSelected={headSelected}
                  />
                );
              })}
          </NewDataTable>
        ) : (
          <div className="noData">
            <p style={{ textAlign: "center" }}>No Cases Found</p>
            <button
              className="addButton"
              onClick={() => {
                handleAddCase();
              }}
            >
              <BsPlus size={24} />
              Add A new case
            </button>
          </div>
        )}
        {show && (
          <ConfirmModal
            title={"Select organization"}
            handleClose={() => {
              setShow(false);
            }}
            handleShow={() => {
              setShow(true);
            }}
            message={
              <div className="selectOrg">
                Select organization
                <SelectOrg />
              </div>
            }
            show={show}
          >
            <button
              type="button"
              className="confirmBtn"
              onClick={() => {
                setShow(false);
              }}
            >
              confirm
            </button>
          </ConfirmModal>
        )}
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    selectedOrg: state.ORGs.selectedOrg,
    cases: state.Cases.cases,
    activities: state.Activities.activities,
    orgs: state.ORGs.orgs,
    isPending: state.Cases.isPending,
    case: state.Cases,
  }),
  (dispatch) => ({
    loadCases: (orgId) => dispatch(loadCasesList(orgId)),
    editStatus: (data) => dispatch(editCaseStatus(data)),
    duplicate: (urgentCase) => dispatch(duplicateCase(urgentCase)),
    deleteCases: (causesIDs) => dispatch(deleteCases(causesIDs)),
  })
)(List);

List.propTypes = {
  selectedOrg: PropTypes.string,
  cases: PropTypes.array,
  loadCases: PropTypes.func,
  history: PropTypes.object,
};
