import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";

import {
  createProject,
  editProject,
  editSMSPayment,
} from "../../reducers/Projects";
import ExtendedForms from "./ExtendedForms";
import List from "./List";
import Project from "./Project";

class Projects extends Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };
  nextPath(path) {
    this.context.router.history.push(path);
  }


  render() {
    return (
      <React.Fragment>
        <Route path={`${this.props.match.url}/list`} component={List} />
        <Route
          path={`${this.props.match.url}/extended-forms`}
          component={ExtendedForms}
        />
        <Route
          path={`${this.props.match.url}/new`}
          render={(props) => {
            return (
              <Project
                {...props}
                // submit={this.handleSubmit}
              />
            );
          }}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProp = (dispatch) => ({
  createProject: (project) => dispatch(createProject(project)),
  editSMSPayment: (data) => dispatch(editSMSPayment(data)),
  editProject: (data) => dispatch(editProject(data)),
});

export default connect(
  (state) => ({
    selectedOrg: state.ORGs.selectedOrg,
    projects: state.Projects.projects,
    activities: state.Activities.activities,
    orgs: state.ORGs.orgs,
    duplicateInit: state.Projects.duplicateInit,
  }),
  mapDispatchToProp
)(Projects);

Projects.propTypes = {
  projects: PropTypes.array,
  selectedOrg: PropTypes.string,
  loadProjects: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  createProject: PropTypes.func,
  editSMSPayment: PropTypes.func,
  editProject: PropTypes.func,
  activities: PropTypes.array,
  orgs: PropTypes.array,
  duplicateInit: PropTypes.object,
};
